import React, { useContext, useState } from "react";
import { Gauge } from "@abb/abb-common-ux-react";
import "./co2-savings.css";
import { RootContext } from "../../../contexts/root-context";

function CO2Saving() {
  const { siteKpis } = useContext(RootContext);

  // bar 1
  const valueForCo2Savings = siteKpis?.siteKPIMetrics?.co2Savings?.value || 1234;
  const maxCo2Savings = siteKpis?.siteKPIMetrics?.co2Savings?.target || 4200;
  const [progressBar1ValueCo2Saving] = useState(valueForCo2Savings);
  const [progressBar1RangeCo2Saving] = useState([0, maxCo2Savings]);

  // bar 2
  const valueForCo2SavingsLastYear = siteKpis?.siteKPIMetrics?.co2Savings?.lastYear || 5900;
  const maxCo2SavingsLastYear = siteKpis?.siteKPIMetrics?.co2Savings?.target || 5900;
  const [progressBar2ValueCo2Saving] = useState(valueForCo2SavingsLastYear);
  const [progressBar2RangeCo2Saving] = useState([0, maxCo2SavingsLastYear]);

  // measure
  const measureValueCo2Saving = siteKpis?.siteKPIMetrics?.co2Savings?.value || 1234;
  const measureKpiCo2Saving = siteKpis?.siteKPIMetrics?.co2Savings?.kpi || "+10% YoY";

  // Helper function to render Gauge
  const renderGauge = (label, value, range) => (
    <Gauge
      style={{ width: "50%" }}
      type="bar"
      label={label}
      min="0"
      max={range[1]}
      minLabel="0%"
      maxLabel="100%"
      value={value}
      formatValue={(val) => `${val}`}
    />
  );

  return (
    <div className="co2-saving-container">
      <div className="co2-saving-container-top-div">
        {renderGauge("Target", progressBar1ValueCo2Saving, progressBar1RangeCo2Saving)}
        <div style={{ width: "50%", marginTop: "4%", marginLeft: "1%" }}>
          <span>YTD</span>
          <div className="divider"></div>
        </div>
      </div>
      <div className="number">{measureValueCo2Saving}</div>
      <div className="co2-saving-container-bottom-div">
        {renderGauge("Last Year", progressBar2ValueCo2Saving, progressBar2RangeCo2Saving)}
        <div style={{ width: "50%", marginTop: "35px", marginLeft: "1%" }}>
          <div className="divider"></div>
          <span> {measureKpiCo2Saving}</span>
        </div>
      </div>
    </div>
  );
}

export default CO2Saving;
