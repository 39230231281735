import React, { useCallback, useEffect, useMemo, useState } from "react";
import MicroOptMetrics from "./metrics";
import { EMCORE_DASHBOARD_BASE_API_URL } from "../../../strings";
import { fetchCharts } from "../api";

function MetricsContainer() {
  const initialMetricsData = useMemo(
    () => ({
      totalLoadMetric: [{ metricName: "# Load Demand", metricScore: "N/A", metricStat: "" }],
      totalGreenEnergy: [{ metricName: "# Green Energy Gen.", metricScore: "N/A", metricStat: "" }],
      totalNonGreenEnergy: [{ metricName: "# Non-Green Energy Gen.", metricScore: "N/A", metricStat: "" }],
      totalGenerationmMetric: [{ metricName: "# Generation", metricScore: "N/A", metricStat: "" }],
      totalEnergyCost: [{ metricName: "# Energy Cost", metricScore: "N/A", metricStat: "" }],
      highestPeakMetric: [{ metricName: "# Highest Peak", metricScore: "N/A", metricStat: "" }],
      totalCostSavings: [{ metricName: "# Grid Intake", metricScore: "N/A", metricStat: "" }],
      peakCosts: [{ metricName: "# Peak Costs", metricScore: "N/A", metricStat: "" }]
    }),
    []
  );

  const [actualMetrics, setActualMetrics] = useState(initialMetricsData);
  const isFileUploaded = useMemo(() => JSON.parse(localStorage.getItem("isFileUploaded")), []);

  const fetchMetricsData = useCallback(async () => {
    try {
      const metricsApiResponse = await fetchCharts(`${EMCORE_DASHBOARD_BASE_API_URL}/output_summary.json`, {});
      const metricsKeys = {
        totalLoadMetric: "Total_Load",
        totalGreenEnergy: "Total_Green_Generation",
        totalNonGreenEnergy: "Total_Non_Green_Generation",
        totalGenerationmMetric: "Total_Generation",
        totalEnergyCost: "Total_Energy_cost",
        highestPeakMetric: "Highest_peak",
        totalCostSavings: "Total_Grid_Intake",
        peakCosts: "Peak_costs"
      };

      const combinedMetrics = Object.entries(metricsKeys).reduce((acc, [key, apiKey]) => {
        acc[key] = [
          {
            metricName: initialMetricsData[key][0].metricName,
            metricScore: isFileUploaded ? metricsApiResponse[apiKey]?.value : "N/A",
            metricStat: isFileUploaded ? metricsApiResponse[apiKey]?.unit : ""
          }
        ];
        return acc;
      }, {});

      setActualMetrics(combinedMetrics);
    } catch (error) {
      console.error("Error fetching the JSON data:", error);
    }
  }, [isFileUploaded, initialMetricsData]);

  useEffect(() => {
    fetchMetricsData();
  }, [fetchMetricsData]);

  const metricComponents = useMemo(
    () =>
      Object.entries(actualMetrics).map(([key, legends], index) => (
        <div
          key={key}
          className="col-sm-6 col-md-4 col-lg-4 col-xl-2"
          style={{ paddingLeft: "8px", marginLeft: index === 0 ? "24px" : "0px" }}
        >
          <MicroOptMetrics position={3} legends={legends} />
        </div>
      )),
    [actualMetrics]
  );

  return <div className="filter-row row" style={{ zIndex: "-1" }}>{metricComponents}</div>;
}

export default MetricsContainer;
