import React, { useContext, useEffect, useState } from "react";
import { Dropdown, DropdownOption } from "@abb/abb-common-ux-react"; // Adjust import as needed
import dropDownSchema from "./dropdown-schema.json";
import { validateJsonSchema, isJsonValidSchema } from "../../../util.js";
import "./dropdown-filter.css";
import { RootContext } from "../../../contexts/root-context.js";

const DynamicDropdown = (props) => {
  const { chartsNameList } = props;
  const { setMicroGridFilteredDropdownList } = useContext(RootContext);
  let defaultSelection = chartsNameList.length > 0 ? [chartsNameList[0]] : [];
  const [selectedValues, setSelectedValues] = useState(defaultSelection[0].title);

  useEffect(() => {
    const dropDownSchemaValues = JSON.stringify(dropDownSchema)
    if (isJsonValidSchema(dropDownSchemaValues)) {
      validateJsonSchema(dropDownSchema); // Pass ignored properties
    } else {
      console.error('Invalid JSON syntax: Missing braces or commas.');
    }
  }, []);


  // Handle selection change
  const handleChange = (selectedCharts) => {
    if (selectedCharts.length > 3) {
      alert("You can only select up to 3 options.");
      return; // Prevent selecting the 4th option
    }
    // Extract only the values from the selected objects
    const selectedValuesOnly = selectedCharts.map(chart => chart.value);
    setSelectedValues(selectedCharts);
    setMicroGridFilteredDropdownList(selectedValuesOnly)
    localStorage.setItem("selected-pred-charts", JSON.stringify(selectedValuesOnly));
  };

  // Extract options from JSON

  const options = chartsNameList?.map((item) => ({
    label: item.title,
    value: item.title.toLowerCase() // Convert to lowercase for consistency
  }));

  return (
    <>
      {dropDownSchema.enable ? <Dropdown
        name="predict-model"
        className="dp-predict-model"
        style={{ paddingLeft: "16px" }}
        label={dropDownSchema.title}
        monochrome={false}
        multiselect={true}
        value={selectedValues}
        onChange={handleChange}
        initialOpen="multi"

      >
        {options?.map((option) => (
          <DropdownOption key={option.value} label={option.label}  value={option.value}  />
        ))}
      </Dropdown> : ""}
    </>
  );
};

export default DynamicDropdown;
