import React, { useContext, useEffect, useState } from "react";
import {
  DropdownOption,
  Dropdown,
  Button
} from "@abb/abb-common-ux-react";
import { getEmCoreTimestamps } from "../../../util";
import { RootContext } from "../../../contexts/root-context";
import filterConfig from "./filters-config.json";
import { useNavigate } from "react-router-dom";
import "./filters.css";

function MicrogridFilterBar() {
  const {
    setstartTS,
    setendTS,
    isInputSettingClicked,
    setisInputSettingClicked
  } = useContext(RootContext);
  const navigate = useNavigate();
  const [monochrome, setMonochrome] = useState(false);
  const [selectedSingle, setSelectedSingle] = useState("Last 24 hours");

  useEffect(() => {}, [isInputSettingClicked]);

  const getFilterTime = (filterVal) => {
    setSelectedSingle(String(filterVal[0].label));
    setstartTS(getEmCoreTimestamps(filterVal[0].value).startDate);
    setendTS(getEmCoreTimestamps(filterVal[0].value).endDate);
  };

  const redirectToInputSetting = () => {
    setisInputSettingClicked(true);
    navigate("input-settings");
  };

  return (
    <div className="filter-row row">
      <div className="col-5 col-m-12 col-s-12">
        <div className="filter-header-opt">{filterConfig.headerTitle}</div>
      </div>
      <div className="col-7 col-m-12 col-s-12">
        <div className="filters-group row">
          <Button
            icon="abb/settings"
            sizeClass="small"
            text="Input parameter setting"
            onClick={redirectToInputSetting}
            shape="pill"
            style={{ marginTop: "8px" }}
            type="primary-black"
          />
          <Dropdown
            id="time-period-emcore"
            className="filter-dropdown col-s-12"
            label="Time Period"
            placeholder={selectedSingle ? selectedSingle : "Select Option"}
            monochrome={monochrome}
            value={selectedSingle}
            onChange={getFilterTime}
          >
            {filterConfig.filterOptions.map((option) => (
              <DropdownOption
                key={option.title}
                label={option.title}
                value={option.value}
              />
            ))}
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

export default MicrogridFilterBar;
