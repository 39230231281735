import React, { useContext, useEffect, useState } from "react";
import { Gauge } from "@abb/abb-common-ux-react";
import "./ev-chargers.css";
import { RootContext } from "../../../contexts/root-context";

function EVChargers() {
  const { siteKpis } = useContext(RootContext);

  const evChargerValue = siteKpis?.siteKPIMetrics?.emobility?.value;
  const [value, setValue] = useState(evChargerValue || 1250);
  const [range] = useState([0, 100]);
  const [decimals] = useState(0);
  const [warnings] = useState([25, 75]);
  const [alarms] = useState([10, 90]);
  const [limitMarkerStyle] = useState("none");

  // Update value state when valueForSolarTarget changes
  useEffect(() => {
    if (evChargerValue !== undefined) {
      setValue(evChargerValue);
    }
  }, [evChargerValue]);
  
  return (
    <div
      className="kpi-gauge-content"
      style={{
        width: "100%",
      }}
    >
      <Gauge
        className="nz-gauge-style"
        type="radial"
        min={range[0]}
        max={range[1]}
        minLabel={" "}
        maxLabel={" "}
        lowAlarm={alarms[0]}
        highAlarm={alarms[1]}
        lowWarn={warnings[0]}
        highWarn={warnings[1]}
        unit="kWh"
        value={value}
        limitStyle={limitMarkerStyle}
        decimals={decimals}
      />
    </div>
  );
}

export default EVChargers;
