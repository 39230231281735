import React, { useContext, useEffect, useState } from "react";
import { Gauge } from "@abb/abb-common-ux-react";
import "./kpi-gauge.css";
import { RootContext } from "../../../contexts/root-context";

function GreenEnergy() {
  const { siteKpis } = useContext(RootContext);
  const valueForSolarTarget = siteKpis?.siteKPIMetrics?.greenEnergy?.solar;
  const [value, setValue] = useState(valueForSolarTarget || 180);
  const [range] = useState([0, 200]);
  const [decimals] = useState(0);
  const [warnings] = useState([20, 100]);
  const [alarms] = useState([5, 150]);
  const [limitMarkerStyle] = useState("none");
  // Update value state when valueForSolarTarget changes
  useEffect(() => {
    if (valueForSolarTarget !== undefined) {
      setValue(valueForSolarTarget);
    }
  }, [valueForSolarTarget]);

  return (
    <div
      className="kpi-gauge-content"
      style={{
        width: "100%",
      }}
    >
      <Gauge
        className="nz-gauge-style"
        type="radial"
        min={range[0]}
        max={range[1]}
        minLabel={" "}
        maxLabel={" "}
        lowAlarm={alarms[0]}
        highAlarm={alarms[1]}
        lowWarn={warnings[0]}
        highWarn={warnings[1]}
        unit="kWh"
        value={value}
        limitStyle={limitMarkerStyle}
        decimals={decimals}
      />
    </div>
  );
}

export default GreenEnergy;
