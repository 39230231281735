import React, { useState, useEffect, useContext } from "react";
import { Sankey, Tooltip } from "recharts";
import DemoSankeyLink from "./DemoSankeyLink";
import DemoSankeyNode from "./DemoSankeyNode";
import { RootContext } from "../../../contexts/root-context";

const colors = ["#ff9e2f", "#008000", "#ff9e2f", "#008000", "grey", "grey"];

function SankeyDemo() {
  const { siteKpis } = useContext(RootContext);
  const energyFlowInitial = siteKpis?.siteKPIMetrics.energyFlow || [];

  const [energyFlow, setEnergyFlow] = useState(energyFlowInitial);

  useEffect(() => {
    setEnergyFlow(energyFlowInitial);
  }, [energyFlowInitial]);

  // Generate nodes and links from energyFlow
  const nodes = [
    { name: "Utility", color: colors[0] },
    { name: "Solar", color: colors[1] },
    { name: "Consumption", color: colors[2] },
    { name: "EVChargers", color: colors[3] },
    { name: "HVAC", color: colors[4] },
    { name: "Others", color: colors[5] },
  ];

  const links = energyFlow.map((flow, index) => ({
    source: nodes.findIndex((node) => node.name === flow.from),
    target: nodes.findIndex((node) => node.name === flow.to),
    value: flow.percentage,
    index: index,
  }));

  // Color gradients for links
  const numColors = colors.length;
  const colorGradients = links.map((link) => ({
    source: colors[link.source % numColors],
    target: colors[link.target % numColors],
  }));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload?.length && payload[0].name.includes("-")) {
      return (
        <div
          className="bg-warning p-3 shadow"
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "100%",
            fontSize: "20px",
            fontFamily: "sans-serif",
            border: "1px solid white",
          }}
        >
          {payload.map((ele, index) => (
            <>
              <small key={index} className="text-secondary">
                {`${ele.name}`} :{`${ele.value}%`}
                {/* {`Name: ${ele.name}`} */}
                {/* {`Value: ${ele.value}`} */}
              </small>
              <br />
            </>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="sankey-charts">
      <div>
        <Sankey
          width={300}
          height={500}
          margin={{ top: 20, bottom: 20 }}
          data={{ nodes, links }}
          nodeWidth={10}
          nodePadding={60}
          linkCurvature={0.61}
          iterations={0}
          link={
            <DemoSankeyLink
              colorGradients={colorGradients}
              data={{ nodes, links }}
            />
          }
          node={
            <DemoSankeyNode
              containerWidth={300}
              colors={colors}
              data={{ nodes, links }}
            />
          }
        >
          {/* <Tooltip /> */}
          {/* <Tooltip
            labelFormatter={(value) => {
              return `Energy%: ${value}`;
            }}
          /> */}
          <Tooltip
            content={<CustomTooltip active={false} payload={[]} label={""} />}
          />
        </Sankey>
      </div>
      <br />
    </div>
  );
}

export default SankeyDemo;
