import React, { useEffect, useState, useCallback, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dialog, Spinner, Button  } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../contexts/root-context";
import "./proofer-form.css"; // Add your custom styles here
import AWS from "aws-sdk";
import jsonSchema from "./proofer-form.json";
import {validateJsonSchema, isJsonValidSchema } from "../../../util.js";
import { postRequestUrlPrefix } from "../../../strings.js";

window.Buffer = window.Buffer || require("buffer").Buffer;

const ProoferForm = (props) => {

  useEffect(() => {
    const jsonString = JSON.stringify(jsonSchema)
    if (isJsonValidSchema(jsonString)) {
      console.log('JSON being validated:', jsonSchema);
      validateJsonSchema(jsonSchema); // Pass ignored properties
    } else {
      console.error('Invalid JSON syntax: Missing braces or commas.');
    }
  }, []);


  const { showDialog, setShowDialog } = props;
  const [programmaticDialogIsOpen, setProgrammaticDialogIsOpen] = React.useState(true);
  const { currentProoferState, setcurrentProoferState } = useContext(RootContext);


  const [formData, setFormData] = useState({
    opportunityNumber: "",
    projectName: "",
    customerName: "",
    productGroup: "",
    file: null,
    techRequirementFrom: "",
    techRequirementTo: "",
    commRequirementFrom: "",
    commRequirementTo: "",
  });


  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);
  const [setUploadSuccess] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [loading, setLoading] = useState(false);

  // AWS S3 configuration
  const S3_BUCKET = "proofer-hackathon-demo-bucket";
  const REGION = "ap-south-1";

  AWS.config.update({

    region: REGION,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  useEffect(() => {}, [showDialog]);
  const uploadFile = (file) => {
    if (!file) {
      alert(jsonSchema.properties.inputs.uploadfile.alert.text);
      return;
    }
    const selectedFileKey = "documents/input/" + selectedFile.name;

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: selectedFileKey,
      ContentType: file.type,
    };

    setUploading(true);

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(`Progress: ${Math.round((evt.loaded / evt.total) * 100)}%`);
      })
      .send((err) => {
        if (err) {
          console.log("Upload Error", err);
          setUploadSuccess(false);
        } else {
          console.log("Upload Success");
          setUploadSuccess(true);
        }
        setUploading(false);
      });
  };


  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

 
  const handleSubmit = useCallback(() => {
    setLoading(true);
  
    const data = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      data.append(key, value);
    });
  
    console.log("formData data", formData);
  
    const mockData = {
      name: selectedFile.name,
      path: "/store/documents/proofer/Enquiry Document RFQ 1.pdf",
      path_type: "local",
      type: "pdf",
      metadata: {
        author: "John Doe",
        date: "2023-09-01",
      },
      page_range_array: [[102, 117]],
      reset_db: true,
    };
  
    const headers = {
      "Content-Type": "application/json",
    };
  
    axios
      .post(postRequestUrlPrefix + "process_pdf", mockData, { headers })
      .then((response) => {
        setShowDialog(false);
        console.log("response form", response);
        setcurrentProoferState("showtabs");
        localStorage.setItem(
          "prooferFormResponse",
          JSON.stringify(response.data)
        );
        localStorage.setItem("prooferFormData", JSON.stringify(formData));
        navigate("./popup-output-tabs", {
          state: { formData, response: response.data },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [formData, selectedFile, postRequestUrlPrefix, navigate]);
  

  return (
    <div>
      <Dialog
        name="proofer-form"
        isOpen={showDialog}
        sizClass={"large"}
        id="popup-header"
        onClose={() => {
          console.log("onClose");
          setShowDialog(false);
          setProgrammaticDialogIsOpen(false);
        }}
        className="ReactModal__Content ReactModal__Content--after-open ABB_CommonUX_Dialog__dialog ABB_CommonUX_Dialog__dark"
        style={{ "z-index": "3 !important" }}
        showCloseButton={true}
        closeOnEscape={true}
        closeOnLostFocus={true}
        onOpen={() => console.log("onOpen")}
        title={jsonSchema.properties.title.text}
        render={(dlg) => (
          <form id="proofer-form-container">
            <div>
              {" "}
              {loading ? (
                <Spinner
                  style={{ marginLeft: "40%" }}
                  color="red"
                  sizeClass={"medium"}
                ></Spinner>
              ) : (
                ""
              )}
            </div>

            {jsonSchema.properties.inputs.oppurtunity.enable && (
              <>
                <label>{jsonSchema.properties.inputs.oppurtunity.title}</label>
                <input
                  type="text"
                  name="opportunityNumber"
                  placeholder="OPP.__._____"
                  value={formData.opportunityNumber}
                  onChange={handleInputChange}
                />
              </>
            )}
            {jsonSchema.properties.inputs.project.enable && (
                <>
            <label>{jsonSchema.properties.inputs.project.title}</label>
              <input
                type="text"
                name="projectName"
                value={formData.projectName}
                onChange={handleInputChange}
              />
              </>
            )}
            {jsonSchema.properties.inputs.customer.enable && (
                <>
            <label>{jsonSchema.properties.inputs.customer.title}</label>
              <input
                type="text"
                name="customerName"
                value={formData.customerName}
                onChange={handleInputChange}
              />
              </>
            )}
            {jsonSchema.properties.inputs.productgroup.enable && (
                <>
            <label>{jsonSchema.properties.inputs.productgroup.title}</label>
              <select
                name="productGroup"
                value={formData.productGroup}
                className="product-group-select"
                onChange={handleInputChange}
              >
                 {jsonSchema.properties.inputs.productgroup.Dropdown.opt1.enable && (
                <option value="">{jsonSchema.properties.inputs.productgroup.Dropdown.opt1.title}</option>
                 )}
                  {jsonSchema.properties.inputs.productgroup.Dropdown.opt2.enable && (
                <option value="AIS">{jsonSchema.properties.inputs.productgroup.Dropdown.opt2.title}</option>
                  )}
                   {jsonSchema.properties.inputs.productgroup.Dropdown.opt3.enable && (
                <option value="GIS">{jsonSchema.properties.inputs.productgroup.Dropdown.opt3.title}</option>
                   )}
                    {jsonSchema.properties.inputs.productgroup.Dropdown.opt4.enable && (
                <option value="Outdoor CB">{jsonSchema.properties.inputs.productgroup.Dropdown.opt4.title}</option>
                    )}
                     {jsonSchema.properties.inputs.productgroup.Dropdown.opt5.enable && (
                <option value="RMU">{jsonSchema.properties.inputs.productgroup.Dropdown.opt5.title}</option>
                     )}
              </select>
              </>
            )}

            {jsonSchema.properties.inputs.uploadfile.enable && (
                <>
            <label>{jsonSchema.properties.inputs.uploadfile.title}</label>
              <span>
                <input
                  type="file"
                  onChange={handleFileInput}
                  accept="application/pdf"
                />
                <Button
                  onClick={() => uploadFile(selectedFile)}
                  disabled={uploading}
                  text={uploading ? jsonSchema.properties.inputs.uploadfile.Button.uploadingtext : jsonSchema.properties.inputs.uploadfile.Button.uploadtext}
                  shape="rounded"
                  sizeClass="small"
                  type="primary-red"
                />
              </span>
              </>
            )}
            {jsonSchema.properties.inputs.technicalrequiremnet.enable && (
              <div className="tech-requirements">
                <label>{jsonSchema.properties.inputs.technicalrequiremnet.title1} 
                    &nbsp;&nbsp;{jsonSchema.properties.inputs.technicalrequiremnet.title}</label>
                <input
                  type="text"
                  name="techRequirementFrom"
                  className="page-range"
                  placeholder="From"
                  value={formData.techRequirementFrom}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="techRequirementTo"
                  placeholder="To"
                  className="page-range"
                  value={formData.techRequirementTo}
                  onChange={handleInputChange}
                />
              </div>
            )}

            {jsonSchema.properties.inputs.commercialrequirement.enable && (
              <div className="comm-requirements">
                <label>{jsonSchema.properties.inputs.commercialrequirement.title}</label>
                <input
                  type="text"
                  name="commRequirementFrom"
                  className="page-range"
                  placeholder="From"
                  value={formData.commRequirementFrom}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  name="commRequirementTo"
                  className="page-range"
                  placeholder="To"
                  value={formData.commRequirementTo}
                  onChange={handleInputChange}
                />
              </div>
            )}

            <div className="buttons" style={{ "margin-top": "5px" }}>
              {jsonSchema.properties.inputs.create.enable && (
                <Button
                  text={jsonSchema.properties.inputs.create.text}
                  onClick={() => handleSubmit()}
                  shape="pill"
                  type="primary-red"
                />
              )}
              {jsonSchema.properties.inputs.cancel.enable && (
                <Button
                  text={jsonSchema.properties.inputs.cancel.text}
                  style={{ "margin-left": "15px" }}
                  onClick={() => setShowDialog(false)}
                  shape="pill"
                  type="primary-white"
                />
              )}
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default ProoferForm;
