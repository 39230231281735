import React from "react";
import "./PowerDevice.css";

const PowerDevice = ({ device }) => {
    const getClassNames = () => {
        let classNames = "power-box";
        if (device.isOpen) classNames += " opened";
        if (device.isClosed) classNames += " closed";
        if (device.isTripped) classNames += " tripped";
        if (device.isIsolated) classNames += " isIsolated";
        return classNames;
    };

    return (
        <div className={getClassNames()} style={{ top: device.y, left: device.x }}>
            <div className="power-device-content">
                <div>{device.name}</div>
                <div>{device.value}</div>
            </div>
        </div>
    );
};

export default PowerDevice;