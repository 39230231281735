import React, { useContext } from "react";
import { DropdownOption, Dropdown, Icon } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../contexts/root-context";
// import Co2Emissions from "../Trends/co2-emissions";

function NzDropdownCo2Emission() {
  const { co2EmissionSelectedDuration, setCo2EmissionSelectedDuration } =
    useContext(RootContext);
  const periodOptions = ["Last 24 hours", "Last 30 days"];

  const [monochrome, setMonochrome] = React.useState(false);
  const [initialOpen, setInitialOpen] = React.useState("none");
  const [selectedSingle, setSelectedSingle] = React.useState(periodOptions[1]);
  const getFilterTime = (filterVal) => {
    console.log(filterVal[0].value);
    setSelectedSingle(filterVal[0].value);
    setCo2EmissionSelectedDuration(
      filterVal[0].value === "Last 24 hours" ? "Last 24 hours" : "Last 30 days"
    );
  };
  return (
    <Dropdown
      placeholder={selectedSingle}
      value={selectedSingle}
      onChange={getFilterTime}
      initialOpen={initialOpen === "single"}
    >
      {periodOptions.map((o, i) => (
        <DropdownOption key={o} label={o} value={o} />
      ))}
    </Dropdown>
  );
}
export default NzDropdownCo2Emission;
