import React, { useCallback, useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar/navbar";
import MicrogridFilterBar from "../../components/Microgrid-Opt-Components/Filters/filters";
import MetricsContainer from "../../components/Microgrid-Opt-Components/Metrics/metrics-container";
import MicrogridChartsTab from "./charts-tab";
import { RootContext } from "../../contexts/root-context";
import InputParameterSettings from "./input-parameters";
import { EMCORE_BASE_API_URL } from "../../strings";
import microGridAPICall from "../../components/Microgrid-Opt-Components/api";
import NotificationExample from "../../components/Notification/notification";


// Define the headers object outside the component to avoid recreation on each render
const headers = { 'Content-Type': 'application/json' };

const MicrogridOpti = () => {
    

    const { isInputSettingClicked,microgridObj,setmicrogridObj } = useContext(RootContext);
    const [response, setResponse] = useState(null);
    const { isnotify,setisnotify,endTS,startTS } = useContext(RootContext);
 
    let isFileUploaded = false;  // Boolean value
    const getCheckFileExist =useCallback(async () => {
      
        setisnotify("")
        try {
            const result = await microGridAPICall('get', `${EMCORE_BASE_API_URL}data-exists`, {}, headers);
            
            localStorage.setItem("dashboard-charts-s3-url-input",result.s3url);
            localStorage.setItem("nw-file-last-upload",result.last_modified);
            isFileUploaded=result.success
            localStorage.setItem("isFileUploaded",JSON.stringify(isFileUploaded));
            setmicrogridObj({
                ...microgridObj,
                s3InputUrl:result.s3url,
                dataExist:result.success
            })
            setResponse(result);
         
        } catch (error) {
            localStorage.setItem("isFileUploaded",JSON.stringify(isFileUploaded));
            setisnotify("noFilesUpload")
            setmicrogridObj({
                ...microgridObj,
                dataExist:error.success
            })
            console.error("Get Error:", error);
        }
    },[]);

    // Call API only once when the component mounts
    useEffect(() => {
        getCheckFileExist();
    }, [getCheckFileExist]); // Empty dependency array ensures this only runs once
   
    return (
        <>  {isnotify === "networkfiles" ? <NotificationExample notitype={isnotify} /> : ""}
          {isnotify === "objfuntions" ? <NotificationExample notitype={isnotify} /> : ""}
          {isnotify === "noFilesUpload" ? <NotificationExample notitype={isnotify} /> : ""}
            <Navbar />
            {isInputSettingClicked ? (
                <InputParameterSettings />
            ) : (
                <>
                    <MicrogridFilterBar />
                    <MetricsContainer />
                    <MicrogridChartsTab />
                </>
            )}
        </>
    );
};

export default React.memo(MicrogridOpti);
