import React, { useContext, useState } from "react";
import { Gauge } from "@abb/abb-common-ux-react";
import "./co2-savings.css";
import { RootContext } from "../../../contexts/root-context";

function CO2ETons() {
  const { siteKpis } = useContext(RootContext);

  // bar 1
  const valueForCo2Estimate = siteKpis?.siteKPIMetrics?.co2Consumption?.value || 1234;
  const maxCo2Consumption = siteKpis?.siteKPIMetrics?.co2Consumption?.target || 4200;
  const [progressBar1Value] = useState(valueForCo2Estimate);
  const [progressBar1Range] = useState([0, maxCo2Consumption]);

  // bar 2
  const lastYearCo2ConsumptionValue = siteKpis?.siteKPIMetrics?.co2Consumption?.lastYear || 5900;
  const lastYearCo2ConsumptionMax = siteKpis?.siteKPIMetrics?.co2Consumption?.target || 4200;
  const [progressBar2Value] = useState(lastYearCo2ConsumptionValue);
  const [progressBar2Range] = useState([0, lastYearCo2ConsumptionMax]);

  // measure
  const measureValue = siteKpis?.siteKPIMetrics?.co2Savings?.value || 1234;
  const measureKpi = siteKpis?.siteKPIMetrics?.co2Savings?.kpi || "+10% YoY";

  // Helper function to render Gauge
  const renderGauge = (label, value, range) => (
    <Gauge
      style={{ width: "50%" }}
      type="bar"
      label={label}
      min="0"
      max={range[1]}
      minLabel="0%"
      maxLabel="100%"
      value={value}
      formatValue={(val) => `${val}`}
    />
  );

  return (
    <div className="co2-saving-container">
      <div className="co2-saving-container-top-div">
        {renderGauge("Target", progressBar1Value, progressBar1Range)}
        <div style={{ width: "50%", marginTop: "4%", marginLeft: "1%" }}>
          <span>YTD</span>
          <div className="divider"></div>
        </div>
      </div>
      <div className="number">{measureValue}</div>
      <div className="co2-saving-container-bottom-div">
        {renderGauge("Last Year", progressBar2Value, progressBar2Range)}
        <div style={{ width: "50%", marginTop: "35px", marginLeft: "1%" }}>
          <div className="divider"></div>
          <span>{measureKpi}</span>
        </div>
      </div>
    </div>
  );
}

export default CO2ETons;
