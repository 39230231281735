import React, { useContext } from "react";
import {
  DropdownOption,
  Dropdown,
} from "@abb/abb-common-ux-react";
import getTimestamps from "../../util";
import { RootContext } from "../../contexts/root-context";

import "./filters.css";
function Filters() {
  const { setstartTS, endTS, setendTS } =
    useContext(RootContext);
  const filterOptions = [
    {
      title: "Last 5 minutes",
      value: 5,
    },
    {
      title: "Last 15 minutes",
      value: 15,
    },
    {
      title: "Last 30 minutes",
      value: 30,
    },
    {
      title: "Last 1 hour",
      value: 60,
    },
    {
      title: "Last 3 hours",
      value: 180,
    },
    {
      title: "Last 6 hours",
      value: 360,
    },
    {
      title: "Last 12 hours",
      value: 720,
    },
    {
      title: "Last 24 hours",
      value: 1440,
    },
    {
      title: "Last 2 days",
      value: 2880,
    },
  ];
  const [monochrome, setMonochrome] = React.useState(false);
  const [selectedSingle, setSelectedSingle] = React.useState("Last 1 hour");
  const getFilterTime = (filterVal) => {
    setSelectedSingle(String(filterVal[0].label));
    setstartTS(getTimestamps(filterVal[0].value).startDate);
    setendTS(getTimestamps(filterVal[0].value).endDate);
  };


  return (
    <>
      <div className="filter-row row">
        <div className="col-5 col-m-12 col-s-12">
          <div className="filter-header">DR Dashboard</div>
        </div>
        <div className="col-7 col-m-12 col-s-12">
          {/* {startTS} To {endTS} */}
          <div className="filters-group row">
            <Dropdown
              id="time-period-dd"
              className="filter-dropdown col-s-12"
              label="Time Period"
              placeholder={selectedSingle ? selectedSingle : "Select Option"}
              monochrome={monochrome}
              value={selectedSingle}
              onChange={getFilterTime}

            >
              {filterOptions.map((o, i) => (
                <DropdownOption key={o.title} label={o.title} value={o.value} />
              ))}
            </Dropdown>
            <Dropdown
              className="filter-dropdown col-s-12"
              label="Location"
              placeholder="ABB SACE BUILDING"
              monochrome={monochrome}
            >
              <DropdownOption key="FRFC" label="ABB SACE BUILDING" value="ABB SACE BUILDING" />
            </Dropdown>
            <Dropdown
              className="filter-dropdown col-s-12"
              label="Substation ID"
              placeholder="ABB Via Pescaria 5 24123 BG IT"
              monochrome={monochrome}
            >
              <DropdownOption key="ABB Via Pescaria 5 24123 BG IT" label="ABB Via Pescaria 5 24123 BG IT" value="ABB Via Pescaria 5 24123 BG IT" />

            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}
export default Filters;
