import React, { useContext } from "react";
import { DropdownOption, Dropdown } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../contexts/root-context";

function NzDropdownEnergyMixForecast() {
  const { setEnergyMixForecastSelectedDuration } = useContext(RootContext);
  const periodOptions = ["Daily", "Monthly"];
  const [initialOpen, setInitialOpen] = React.useState("none");
  const [selectedSingle, setSelectedSingle] = React.useState(periodOptions[1]);
  const getFilterTime = (filterVal) => {
    console.log(filterVal[0].value);
    setSelectedSingle(filterVal[0].value);
    setEnergyMixForecastSelectedDuration(
      filterVal[0].value === "Monthly" ? "Monthly" : "Daily"
    );
  };

  return (
    <Dropdown
      placeholder={selectedSingle}
      value={selectedSingle}
      onChange={getFilterTime}
      initialOpen={initialOpen === "single"}
    >
      {periodOptions.map((o, i) => (
        <DropdownOption key={o} label={o} value={o} />
      ))}
    </Dropdown>
  );
}
export default NzDropdownEnergyMixForecast;
