import React, { useState, useEffect, useMemo } from 'react';


import {  Datagrid, Icon, Button } from "@abb/abb-common-ux-react";
import "./energy-audit-summary.css"
import energyAudit from './energy-audit-summary.json'
import energyAuditText from './energy-audit-summary-text.json'
import { validateJson, isJsonValid } from '../../util';

const EnergyAuditSummary = () => {

    useEffect(() => {
        const jsonString = JSON.stringify(energyAudit);
         
        if (isJsonValid(jsonString)) {
          validateJson(energyAudit ,energyAudit.schema);
        } else {
         
          console.error('Invalid JSON syntax: Missing braces or commas.');
        }
      }, []);


    const lorem = energyAuditText.lorem;



    const TestComp = ({ i }) => {
        useEffect(() => {
            return () => {
            
            };
        }, []);

        return (
            <>
                <Icon name="abb/alarm_bell" style={{ marginRight: '16px' }} onClick={() => alert(`Clicked icon on row ${i}`)} />
                <Button sizeClass="small" text={`Button ${i}`} onClick={() => alert(`Clicked button on row ${i}`)} />
            </>
        );
    };
    const MemoizedTestComp = React.memo(TestComp);

    const groupData = energyAudit.groupData;

    const initialData = useMemo(
        () =>
            lorem
                .match(/(.*?\s){2}/g)
                .map((k, i) => ({
                    rowId: `row-key-${i}`,
                    fields: {
                        Id: `${groupData[i]}`,
                        Name: k,
                        Value: `${k.length * 12}`,

                        CustomContent: (
                            <div>
                                <MemoizedTestComp key={`TestComp-${'row-key-' + i}`} i={i} />
                                {/* Other custom content */}
                            </div>
                        ),
                    },
                    expansionContent: (
                        <div
                            style={{
                                minHeight: '40px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border: '1px dashed silver',
                                padding: '24px',
                            }}
                        >
                            <p style={{ marginBottom: '20px', fontSize: '18px' }}>Are you sure to delete?</p>
                            <div>
                                <p>
                                    <span style={{ display: 'inline-block', width: '100px' }}>Name:</span> <span>{groupData[i]}</span>
                                </p>
                                <p>
                                    <span style={{ display: 'inline-block', width: '100px' }}>Value:</span> <span>{`${k.length * 12}`}</span>
                                </p>
                            </div>
                            <Button text="Delete" style={{ marginTop: '20px' }} data={i} onClick={(e, data) => deleteRow(e, data)} />

                        </div>
                    ),
                    isSelected: i % 4 === 0 ? null : false,
                    disableSelect: i % 5 === 0,
                    rowStatus: i % 7,
                    fieldStatuses: { Value: i % 7 },
                })),
        []
    );


    const initialColumns = energyAudit.initialColumns;

    
    const [currentPage, setCurrentPage] = useState(0);
    
    const [data, setData] = useState(initialData);
    const [columns, setColumns] = useState(initialColumns);



    const [sortOrder, setSortOrder] = useState(undefined);
    const [columnOrder, setColumnOrder] = useState(undefined);
 

    const [columnFilters, setColumnFilters] = energyAudit.columnFilters;

    const [activeRow, setActiveRow] = useState(undefined);
    const [hideColumnFilters, setHideColumnFilters] = useState(false);

    const handleSelectRow = (rowIndex) => {
        let allSelected = false;
        let noneSelected = true;
        let someSelected = false;
        setData(
            data.map((d, i) => {
                const newDatum = Object.assign(d, { isSelected: rowIndex === i ? !d.isSelected : d.isSelected });
                allSelected = allSelected && d.isSelected;
                noneSelected = noneSelected && !d.isSelected;
                someSelected = someSelected || d.isSelected;
                return newDatum;
            })
        );
    };


    const handleToggleRowExpansion = (expandedRows, expandedRowIds) => {
        setData(data.map((d, i) => Object.assign(d, { isExpanded: expandedRows[i] === true })));
    };

    const handleRowActivation = (rowIndex, rowId) => {
        setData(data.map((d, i) => Object.assign(d, { isActive: d.rowId === rowId })));
        setActiveRow(data.filter((r) => r.rowId === rowId)[0]);
    };

    const deleteRow = (e, rowIndex) => {
        setData(data.filter((d, i) => i !== rowIndex).map((d, i) => Object.assign(d, { isExpanded: false })));
    };

    const handleSelectAll = (oldValue, affectedRowsBegin, affectedRowsEnd) => {
        if (oldValue === 2) {
            setData(data.map((d, i) => (d.isSelected === null || d.disableSelect ? d : i >= affectedRowsBegin && i <= affectedRowsEnd ? Object.assign(d, { isSelected: false }) : d)));
        } else {
            setData(data.map((d, i) => (d.isSelected === null || d.disableSelect ? d : i >= affectedRowsBegin && i <= affectedRowsEnd ? Object.assign(d, { isSelected: true }) : d)));
        }
    };

    const handleColumnFilterChange = (newFilters) => {
        setColumnFilters(newFilters);
        const filteredData = initialData
            .map((c) => Object.assign({}, c))
            .filter((c) => {
                return (
                    (isBlank(newFilters.Id.value) || ('' + c.fields.Id).toLowerCase().indexOf(newFilters.Id.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.Name.value) || ('' + c.fields.Name).toLowerCase().indexOf(newFilters.Name.value.toLowerCase()) > -1) &&
                    (isBlank(newFilters.Value.value) || ('' + c.fields.Value).toLowerCase().indexOf(newFilters.Value.value.toLowerCase()) > -1)

                );
            });
        setData(filteredData);
        
        setCurrentPage(0);
    };

    function isBlank(str) {
        return str === null || str === undefined || (typeof str === 'string' && /^\s*$/.test(str));
    }

    useEffect(() => {
        // Effect code here
        return () => {
            // Cleanup code here
        };
    }, []);

    // Handlers and other functions

    return (
        <>
            {/* Your JSX code here */}
            <Datagrid
                id='energy-audit-summary'
                style={{ height: '300px', width: "100%" }}
                monochrome={true}
                data={data}
                columns={columns}
                
                page={currentPage}
                rowHeight="small"
                zebraColoring={4}
                enableSorting={true}
                enableRowExpansion={true}

                enableRowActivation={true}
                enableColumnDragging={true}

                enableColumnResize={true}

                onToggleRowSelect={handleSelectRow}
                onToggleSelectAll={handleSelectAll}
                onActivateRow={(i, rowId) => handleRowActivation(i, rowId)}
                onToggleRowExpansion={(v, rowIds) => handleToggleRowExpansion(v, rowIds)}
                sortOrder={sortOrder}
                onSort={(so) => setSortOrder(so)}
                columnOrder={columnOrder}
                onColumnOrderChange={(order) => setColumnOrder(order)}
                onColumnWidthsChange={(newWidths) => {
                    setColumns(
                        columns.map((c) => {
                            const obj = Object.assign(c, { width: newWidths[c.fieldKey] });
                            return obj;
                        })
                    );
                }}

                onClickColumnFiltersIcon={() => setHideColumnFilters(!hideColumnFilters)}
                onClickClearColumnFilters={() => {
                    const newFilters = Object.assign({}, columnFilters);
                    Object.keys(newFilters).forEach((k) => {
                        newFilters[k].value = '';
                    });
                    handleColumnFilterChange(newFilters);
                    setHideColumnFilters(true);
                }}
                customMessage={data.length !== 0 ? undefined : 'Nothing in data'}
            />

        </>
    );
};

export default EnergyAuditSummary;
