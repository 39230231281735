import React, { useEffect, useContext, useState } from "react";
import { Gauge, Icon, Spinner, Link } from "@abb/abb-common-ux-react";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import "./power-measurement.css";
import { BASE_URL, ERROR_LIST } from "../../strings";
function PowerMeasurement() {
  const { startTS, endTS } = useContext(RootContext); //setSpinner is used to show the spinner when the page is loading
  const [powerMeasurementResponse, setpowerMeasurementResponse] = useState({});
  const [isSpin, setispin] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchData();
  }, [startTS]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        BASE_URL +
          `SMDemo-PowerMeasurementServiceLambda?startDateTime=${startTS}&endDateTime=${endTS}`
      );
      setpowerMeasurementResponse(response.data);
      setispin(false);
      setError(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setError(true);
        } else if (status >= 500 && status < 600) {
          setError(true);
        } else {
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };
  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={() => {
                  fetchData();
                }}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              <Gauge
                className="gauge-style"
                type="radial"
                min={powerMeasurementResponse?.activePower?.minRange}
                max={
                  parseInt(powerMeasurementResponse?.activePower?.maxRange) == 0
                    ? 1
                    : parseInt(powerMeasurementResponse?.activePower?.maxRange)
                }
                minLabel={powerMeasurementResponse?.activePower?.minRange}
                maxLabel={parseInt(
                  powerMeasurementResponse?.activePower?.maxRange
                )}
                lowWarn={powerMeasurementResponse?.activePower?.thresholds.min}
                highWarn={powerMeasurementResponse?.activePower?.thresholds.max}
                highAlarm="1070"
                label={powerMeasurementResponse?.activePower?.title}
                unit={powerMeasurementResponse?.activePower?.unit}
                value={parseInt(
                  powerMeasurementResponse?.activePower?.currentValue
                )}
              />
              <Gauge
                className="gauge-style"
                type="radial"
                min={powerMeasurementResponse?.reactivePower?.minRange}
                max={
                  parseInt(powerMeasurementResponse?.reactivePower?.maxRange) ==
                  0
                    ? 1
                    : parseInt(
                        powerMeasurementResponse?.reactivePower?.maxRange
                      )
                }
                minLabel={powerMeasurementResponse?.reactivePower?.minRange}
                maxLabel={parseInt(
                  powerMeasurementResponse?.reactivePower?.maxRange
                )}
                highAlarm="120"
                lowWarn={
                  powerMeasurementResponse?.reactivePower?.thresholds.min
                }
                highWarn={
                  powerMeasurementResponse?.reactivePower?.thresholds.max
                }
                label={powerMeasurementResponse?.reactivePower?.title}
                unit={powerMeasurementResponse?.reactivePower?.unit}
                value={parseInt(
                  powerMeasurementResponse?.reactivePower?.currentValue
                )}
              />
              <Gauge
                className="gauge-style"
                type="radial"
                min={parseInt(powerMeasurementResponse?.powerFactor?.minRange)}
                max={
                  parseInt(powerMeasurementResponse?.powerFactor?.maxRange) == 0
                    ? 1
                    : parseInt(powerMeasurementResponse?.powerFactor?.maxRange)
                }
                minLabel="0"
                maxLabel={parseInt(
                  powerMeasurementResponse?.powerFactor?.maxRange
                )}
                lowWarn={
                  powerMeasurementResponse?.powerFactor?.thresholds?.minRange
                }
                highWarn={
                  powerMeasurementResponse?.powerFactor?.thresholds?.maxRange
                }
                label={powerMeasurementResponse?.powerFactor?.title}
                unit={powerMeasurementResponse?.powerFactor?.unit}
                value={parseFloat(
                  powerMeasurementResponse?.powerFactor?.currentValue
                )}
                decimals={4}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default PowerMeasurement;
