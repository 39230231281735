import React from 'react';
import './card.css';

function Card(props) {
  const { widgetName, component, applystyle, applyScroll } = props;

  return (
    <div className='chart-card' >

      <div className='chart-header'>
        <div className='chart-title'>
          {widgetName}
        </div>
        <div className='card-update-notification'>
          {/* Last updated  {widgetUpdateTime} mins ago */}
        </div>
      </div>
      <hr />
      {applyScroll ? <div className="card-content card-content-scroll" >
        {component}
      </div> :
        <div className={applystyle ? 'card-content card-content-even-align' : 'card-content'} >
          {component}
        </div>
      }
    </div>
  );
}

export default Card;


