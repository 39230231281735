import React, { useContext } from "react";
import "./energy-cost.css";
import { RootContext } from "../../../contexts/root-context";

function EnergyCost() {
  const { siteKpis } = useContext(RootContext);
  const powerCostDay = siteKpis?.siteKPIMetrics?.powerCostDay?.value || 205;
  const powerCostMonth =
    siteKpis?.siteKPIMetrics?.powerCostMonth?.value || 1237;

  return (
    <div className="outer-container">
      <div className="inner-conatianer-1">
        <span>day so far</span>
        <div className="divider"></div>
        <span className="number">{powerCostDay}</span>
      </div>
      <div className="inner-conatianer-2">
        <span>month so far</span>
        <div className="divider"></div>
        <span className="number">{powerCostMonth}</span>
      </div>
    </div>
  );
}

export default EnergyCost;
