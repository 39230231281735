import React from "react";
import NetZeroCard from "../../components/Net-Zero-Components/Net-Zero-Card/net-zero-card";
import { HOME_CARD_JSON } from "../../strings";
import "./home.css";

function Home() {
  return (
    <div className="home-container">
      <div className="home-container-left-div">
        {HOME_CARD_JSON.slice(0, 1).map((widget) => (
          <NetZeroCard
            key={widget.widgetName}
            widgetName={widget.widgetName}
            widgetType={widget.widgetType}
            nzcomponent={widget.nzcomponent}
            enabled={widget.enabled}
          />
        ))}
      </div>
      <div className="home-container-right-div">
        {HOME_CARD_JSON.slice(1).map((widget) => (
          <NetZeroCard
            key={widget.widgetName}
            widgetName={widget.widgetName}
            widgetType={widget.widgetType}
            nzcomponent={widget.nzcomponent}
            enabled={widget.enabled}
          />
        ))}
      </div>
    </div>
  );
}

export default Home;
