import React, { useContext, useEffect, useState } from "react";
import "./weather.css";
import { RootContext } from "../../../contexts/root-context";
import axios from "axios";

function Weather() {
  const { siteKpis } = useContext(RootContext);
  const siteTemperature =
    siteKpis?.siteMetadata?.siteWeather?.temperature || 12;
  const weatherDescription =
    siteKpis?.siteMetadata?.siteWeather?.description || "Overcast";
  const siteLocation = siteKpis?.siteMetadata?.siteLocation || "Milan, Italy";

  const [weather, setWeather] = useState({
    loading: false,
    data: {}, // Initialize data as an empty object
    error: false,
  });
  
  useEffect(() => {
    const fetchData = async () => {
      const url = "https://api.openweathermap.org/data/2.5/weather";
      const api_key = "f00c38e0279b7bc85480c3fe775d518c";

      setWeather({ ...weather, loading: true });
      try {
        const response = await axios.get(url, {
          params: {
            q: "Milan",
            units: "metric",
            appid: api_key,
          },
        });
        setWeather({ data: response.data, loading: false, error: false });
      } catch (error) {
        setWeather({ ...weather, data: {}, error: true });
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="weather-container">
      <div>
        <p>
          {siteTemperature} °C | {weatherDescription} | {siteLocation}
        </p>
      </div>
      <div className="cloud-icon">
        {weather.data.weather && weather.data.weather.length > 0 && (
          <img
            className="cloud-img"
            src={`https://openweathermap.org/img/wn/${weather.data.weather[0].icon}@2x.png`}
            alt={weather.data.weather[0].description}
          />
        )}
      </div>
      <div className="des-wind">
        <p>
          Wind Speed: {weather.data.wind ? weather.data.wind.speed : "N/A"} m/s
        </p>
      </div>
    </div>
  );
}

export default Weather;
