import React, { useCallback, useEffect, useRef,useMemo } from 'react';
import * as echarts from 'echarts';
import { Dropdown, DropdownOption } from '@abb/abb-common-ux-react';
import chartData from './data.json'
import energyData from './energy-data.json'
import { validateJson, isJsonValid } from '../../util';
import { INVALID_JSON_SYNTAX } from './string.js';
const EnergyData = () => {
    const chartRef = useRef(null);
   

    const validateEnergyData = useCallback(() => {
        const jsonString = JSON.stringify(energyData);
        const validationResult = isJsonValid(jsonString);

        if (validationResult === true) {
            validateJson(energyData, energyData['energy-data-schema']);
          } else {
            console.error(INVALID_JSON_SYNTAX); // Log the actual error message
          }
          
      }, [energyData]); // Only re-create the function if `energyData` changes
    
      // Use useEffect for side-effects like validation
      useEffect(() => {
        validateEnergyData();
      }, [validateEnergyData]); // Re-run when `validateEnergyData` changes


    const chartOptions = useMemo(() => {
        const data = chartData.data;
        
        return {
            tooltip: {
                trigger: energyData.tooltip.trigger,
                axisPointer: {
                    type:energyData.tooltip.axisPointer.type,
                },
            },
            xAxis: {
                type:energyData.xAxis.type,
                data: data.map(item => item.name),
                axisTick: {
                    alignWithLabel: true,
                },
                name: energyData.xAxis.name,
                axisLabel: {
                    padding: energyData.xAxis.axisLabel.padding,
                    // margin:energyData.xAxis.axisLabel.margin,
                },
                nameLocation: energyData.xAxis.nameLocation,
                nameGap: 30
            },
            yAxis: {
                type: energyData.yAxis?.type,
                axisLabel: {
                    padding: energyData.yAxis.axisLabel.padding, 
                    verticalAlign: energyData.yAxis.axisLabel.verticalAlign,
                    lineHeight: energyData.yAxis.axisLabel.lineHeight, 
                },
                name: energyData.yAxis.name, 
                nameLocation: energyData.yAxis.nameLocation, 
                nameRotate: energyData.yAxis.nameRotate, 
                nameGap: 30,  
                
            },
            series: [{
                type: energyData.series?.type,
                data: data.map(item => item.value),
                itemStyle: {
                    color: '#FF4754', 
                },
            }],
        };
    }, [energyData, chartData]); 


    // Set up the chart and clean up on component unmount
    useEffect(() => {
        const chart = echarts.init(chartRef.current);

        chart.setOption(chartOptions);

        return () => {
            chart.dispose(); // Cleanup when the component unmounts
        };
    }, [chartOptions]); // Re-run the effect when chartOptions change




    const dropdowns = useMemo(() => {
        return energyData.dropdowns.map((dropdown,index ) => (
            <Dropdown  key={`dropdown-${dropdown.key}-${index}`} label={dropdown.label} placeholder={dropdown.placeholder}>
                {dropdown.options.map(option => (
                    <DropdownOption key={option.key} label={option.label} value={option.value} />
                ))}
            </Dropdown>
        ));
    }, [energyData.dropdowns]); // Recompute only when `energyData.dropdowns` changes


    return (

        <div className='energy-data-block'>
            <div className='indicator-container-line'>
        
             {dropdowns}

            </div>
            <div ref={chartRef} style={{ width: '545px', height: '280px', marginTop: "-20px" }} />
        </div>
    )
};

export default EnergyData;
