import { Table } from "@abb/abb-common-ux-react";
import React, { useContext } from "react";
import "./site-information.css";
import { RootContext } from "../../../contexts/root-context";

function SiteInformation() {
  const { siteKpis } = useContext(RootContext);
  const contractualPower = siteKpis?.siteMetadata?.siteContractDetails?.contractualPower || "350kW";
  const maxOccupants = siteKpis?.siteMetadata?.siteContractDetails?.maxOccupants || "300";

  const groupData = [
    { label: "SIZE", value: "15000 sqm" },
    { label: "CONTRACTUAL POWER", value: contractualPower },
    { label: "MAX OCCUPANTS", value: maxOccupants },
    { label: "SOLAR ROOF", value: "50Kw" },
    { label: "EV CHARGERS", value: "4" },
    { label: "ELEVATORS", value: "3" }
  ];

  return (
    <div
      className="margins borders padding"
      style={{ height: "200px", width: "90%", background: "inherit" }}
    >
      <div style={{ height: "100%", overflow: "auto", background: "inherit" }}>
        <Table
          sizeClass={"large"} // small, medium, large
          solidBackground={false}
          borderType={"discreet"} // none, normal
          zebra={false}
          scrollableBody={true}
        >
          <tbody style={{ pointer: "cursor" }}>
            {groupData.map((item) => (
              <tr key={item}>
                <td className="siteInfoName">{item.label}</td>
                <td className="siteInfoValue" style={{ textAlign: "right" }}>
                  <span>{item.value}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default SiteInformation;
