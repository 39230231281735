import React from 'react';
import './em-card.css';
import PeriodList from '../Period-Dropdown-list/period-dropdown-list';

function Card_3(props) {
  const { widgetName, component, applystyle, applyScroll, isTimePeriod } = props;

  return (
    <div className={widgetName == "Energy Monitoring" ? 'chart-card-3-em-monitoring' : 'chart-card-3'} >

      <div className='chart-header-3'>
        <div className='chart-title-3'>
          {widgetName}
        </div>
        {/* <div className='card-update-notification'>
           
        </div> */}
      </div>
      <hr />
      {/* {isTimePeriod ? <PeriodList /> : ""} */}
      {applyScroll ? <div className="card-content-3 card-content-scroll-3" >
        {component}
      </div> :
        <div className={applystyle ? 'card-content-3 card-content-even-align-3' : 'card-content-3'} >
          {component}
        </div>
      }
    </div>
  );
}

export default Card_3;


