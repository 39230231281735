import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import * as echarts from 'echarts';
import { Dropdown, DropdownOption } from '@abb/abb-common-ux-react';
import "./enpi-monitoring.css";
import PeriodList from '../Period-Dropdown-list/period-dropdown-list';
import enpiData from './enpi-data.json'
import enpiMonitoringJsonData from './enpi-monitoring.json'
import { validateJson, isJsonValid } from '../../util';
import { INVALID_JSON_SYNTAX } from './string.js';
const ENPI_MONITORING = () => {
    const chartRef = useRef(null);
  
     
 const validateEnpiMonitoring=useCallback(()=>{
    const jsonString = JSON.stringify(enpiMonitoringJsonData); 
         
        if (isJsonValid(jsonString)) {
          validateJson(enpiMonitoringJsonData,enpiMonitoringJsonData.enpSchema ); 
        } else {
          
          console.error(INVALID_JSON_SYNTAX);
        }
 },[enpiMonitoringJsonData])

 useEffect(() => {
     validateEnpiMonitoring();
}, [validateEnpiMonitoring]);


      const chartOptions=useMemo(()=>{
        const data=enpiData.dataConfig
        return{
            tooltip: {
                trigger: enpiMonitoringJsonData.tooltip.trigger
            },
            xAxis: {
                type: enpiMonitoringJsonData.xAxis.type,
                data: data.map(item => item.name),
                name: enpiMonitoringJsonData.xAxis.name,
                axisLabel: {

                    padding: enpiMonitoringJsonData.xAxis.axisLabel.padding // Add padding/margin between y-axis values and title
                },
                nameLocation: enpiMonitoringJsonData.xAxis.nameLocation,
                axisTick: {
                    alignWithLabel: true
                }
            },
           
            yAxis: {
                type: enpiMonitoringJsonData.yAxis.type,
                
                axisLabel: {

                    padding: enpiMonitoringJsonData.yAxis.axisLabel.padding, // Add padding/margin between y-axis values and title
                    verticalAlign: enpiMonitoringJsonData.yAxis.axisLabel.verticalAlign, // Aligns the labels towards the top
                    lineHeight: enpiMonitoringJsonData.yAxis.axisLabel.lineHeight, // Adjust the line height as needed
                },
                name: enpiMonitoringJsonData.yAxis.name, // Title for y-axis
                nameLocation: enpiMonitoringJsonData.yAxis.nameLocation, // Set y-axis title location to start (left side)
                nameRotate: enpiMonitoringJsonData.yAxis.nameRotate ,// Do not rotate y-axis title (name)
                nameGap: 30,  
            },
            series: [{
                type: enpiMonitoringJsonData.series.type,
                data: data.map(item => item.value),
                itemStyle: {
                    color: '#E12E2E' // Set bar color to #FF4754
                }
            }]
         }
      },[enpiMonitoringJsonData,enpiData])
    
    useEffect(() => {
       
      const chart = echarts.init(chartRef.current);
      chart.setOption(chartOptions);

        return () => {
            chart.dispose();
        };
    }, [chartOptions]);


 const dropdowns =useMemo(()=>{
    return  enpiMonitoringJsonData.dropdowns.map((dropdown,index) => (
        <Dropdown  key={`dropdown-${dropdown.key}-${index}`} label={dropdown.label} placeholder={dropdown.placeholder} >
            {dropdown.options.map(option => (
                <DropdownOption key={option.key} label={option.label} value={option.value} />
            ))}
        </Dropdown>
    ));

 },[enpiMonitoringJsonData.dropdowns])

    return (

        <div className='enpi-block'>
            <div className='indicator-container-line'>
              
            {dropdowns}

                <PeriodList />
            </div>
            <div ref={chartRef} style={{ width: '545px', height: '280px' }} />
        </div>
    )
};


export default ENPI_MONITORING;
