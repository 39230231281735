import React, { useContext } from "react";
import PowerDevice from "./power-devices";
import "./main-switchboard.css";
import mainSwitchboard from "../../../assets/net-zero-images/Main_Switchboard.png";
import { RootContext } from "../../../contexts/root-context";

const MainSwitchboard = () => {
    const { digitalTwinsData } = useContext(RootContext);

    let powerDevices = [
        {
            id: "MAIN_QG3.asset_fast",
            name: "MAIN_QG3",
            value: "10 KW",
            y: "320px",
            x: "30px",
            isOpen: true,
            isClosed: false,
            isTripped: false,
            isIsolated: false,
        },
        {
            id: "MAIN_QG2.asset_fast",
            name: "MAIN_QG2",
            value: "10 KW",
            y: "320px",
            x: "120px",
            isOpen: false,
            isClosed: true,
            isTripped: false,
            isIsolated: false,
        },
        {
            id: "MAIN_QG4.asset_fast",
            name: "MAIN_QG4",
            value: "10 KW",
            y: "95px",
            x: "520px",
            isOpen: true,
            isClosed: false,
            isTripped: false,
            isIsolated: false,
        },
        {
            id: "MAIN_QG1.asset_fast",
            name: "MAIN_QG1",
            value: "10 KW",
            y: "320px",
            x: "525px",
            isOpen: true,
            isClosed: false,
            isTripped: false,
            isIsolated: false,
        },
    ];

    powerDevices.forEach((device) => {
        const matchingDevice = digitalTwinsData?.find((item) => item.device === device.id);
        if (matchingDevice) {
            device.value = matchingDevice.power;
        }
    });

    return (
        <div className="parent">
            <img
                src={mainSwitchboard}
                alt="Main Switchboard"
                height="600px"
                width="900px"
            />
            {powerDevices.map((device) => (
                <PowerDevice key={device.id} device={device} />
            ))}
        </div>
    );
};

export default MainSwitchboard;
