import React, { useContext } from "react";
import { Layer, Rectangle } from "recharts";

function DemoSankeyNode({
  x,
  y,
  width,
  height,
  index,
  payload,
  containerWidth,
  data,
}) {
  const isOut = x + width + 6 > containerWidth;
  const { links } = data;

  // Find if there's any link associated with this node
  const linked = links.some(
    (link) => link.source === index || link.target === index
  );

  // Show the node label only if there's a link
  const showLabel = linked && payload.value !== 0;

  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={payload.color}
        fillOpacity="0.8"
      />
      {showLabel && (
        <text
          textAnchor={isOut ? "end" : "start"}
          x={isOut ? x - 6 : x + width + 6}
          y={y + height / 2}
          fontSize="12"
          stroke="none"
          strokeWidth="0"
          fill="#000000"
          fontFamily="sans-serif"
        >
          {payload.name}
        </text>
      )}
    </Layer>
  );
}

export default DemoSankeyNode;
