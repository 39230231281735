import React, { useEffect, useState, useMemo, useCallback } from "react";
import { DonutChart, Spinner, Link } from "@abb/abb-common-ux-react";
import { NZ_ERROR_LIST } from "../../../strings";
import axios from "axios";
import "./co2-emission.css";
import jsonSchema from "./home.json";

function Co2Emission() {
  const [isSpin, setIsSpin] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const fetchCo2EmissionData = useCallback(async () => {
    setIsSpin(true);
    try {
      const response = await axios.get(
        "https://bzxhlhpxj7.execute-api.us-east-1.amazonaws.com/api/multisite_analysis"
      );
      setData(response.data);
      setError(false);
    } catch (error) {
      console.error("Failed to fetch CO₂ emission data:", error);
      setError(true);
      setData([]);
    } finally {
      setIsSpin(false);
    }
  }, []);
  

  useEffect(() => {
    fetchCo2EmissionData();
    const interval = setInterval(fetchCo2EmissionData, 15 * 60 * 1000);
    return () => clearInterval(interval);
  }, [fetchCo2EmissionData]);

  const totalCO2 = useMemo(
    () => data.reduce((acc, building) => acc + building.co2, 0),
    [data]
  );

  const formattedData = useMemo(() => {
    const colors = jsonSchema.CO2_EMISSION.colors;
    return data.map((building, index) => {
      const co2Percentage = ((building.co2 / totalCO2) * 100).toFixed(1);
      return {
        key: building.name,
        value: co2Percentage,
        label: building.name,
        color: colors[index % colors.length],
        co2: building.co2.toFixed(2),
      };
    });
  }, [data, totalCO2]);
  

  const [legendPosition] = useState("right");
  const [valueHiding] = useState(true);
  const [enableHoverOnChart] = useState(true);
  const [enableHoverOnLegend] = useState(true);
  const [customLegendTemplate] = useState(false);
  const [hiddenItems, setHiddenItems] = useState([]);
  const [hoverItem, setHoverItem] = useState();

  const formatLegendItem = useCallback((d) => (
    <span>
      <span style={{ fontSize: "80%" }}>{d?.value}</span>
      <span style={{ color: d.color }}>{d?.label}</span>
    </span>
  ), []);

  const dominantValue = useMemo(() => {
    return formattedData
      .slice()
      .filter((i) => hiddenItems.indexOf(i.key) === -1)
      .sort((a, b) => (a.value < b.value ? 1 : -1))[0];
  }, [formattedData, hiddenItems]);

  const label = hoverItem?.label || dominantValue?.label || "";
  const valueText = hoverItem?.value || dominantValue?.value || "";
  const description = hoverItem?.co2 || dominantValue?.co2 || "";

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {NZ_ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link style={{ color: "#1f1f1f" }} onClick={fetchCo2EmissionData}>
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data?.length > 0 ? (
                <div style={{ minHeight: "200px" }}>
                  <div
                    className="resizable"
                    style={{
                      width: "100%",
                      height: "100%",
                      minWidth: "200px",
                      minHeight: "100px",
                    }}
                  >
                    <DonutChart
                      style={{
                        width: "100%",
                        height: "100%",
                        paddingTop: "8px",
                      }}
                      legendPosition={legendPosition}
                      allowValueHiding={valueHiding}
                      disableHoverOnChart={!enableHoverOnChart}
                      disableHoverOnLegend={!enableHoverOnLegend}
                      legendItemFormatter={
                        customLegendTemplate ? formatLegendItem : undefined
                      }
                      label={label}
                      valueText={`${valueText}%`}
                      description={description}
                      hiddenItems={hiddenItems}
                      onHiddenItemsChanged={(items) => setHiddenItems(items)}
                      onHover={(item) =>
                        setHoverItem(
                          formattedData.filter((i) => i.key === item)[0]
                        )
                      }
                      onBlur={() => setHoverItem(undefined)}
                      data={formattedData}
                    />
                  </div>
                </div>
              ) : (
                <p className="center-text-error">{NZ_ERROR_LIST.NO_DATA}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default React.memo(Co2Emission);
