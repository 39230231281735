import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Button, TabControl, TabItem } from "@abb/abb-common-ux-react";
import { RootContext } from "../../../contexts/root-context";
import "./popup-output-tabs.css"; // Add your custom styles here
import * as XLSX from "xlsx";
import ProoferForm from "../popup-form/proofer-form";
import jsonSchema from "./popup-output-tabs-schema.json";
import { isJsonValidSchema, validateJsonSchema } from "../../../util";
import { postRequestUrlPrefix } from "../../../strings";
import "../proofer-navbar.css";

const ProoferTabs = () => {
  const [showDialog, setShowDialog] = useState(false); // State to control dialog visibility
  const { currentProoferState, setcurrentProoferState } =
    useContext(RootContext);
  const [activeTab, setActiveTab] = useState(0);
  const [type] = useState("primary");
  const documenId = localStorage.getItem("current-doc-id");
  const [docSpecSummary, setDocSpecSummary] = useState([]);
  const [proposeProdData, setProposeProdData] = useState({});
  const [docData, setDocData] = useState({
    metadata: {
      oppno: "Loading..",
      filename: "Loading..",
      customerName: "Loading..",
      project: "Loading..",
      state: "Loading..",
      productgroup: "Loading..",
      createdon: "Loading..",
      id: "Loading..",
    },
  });

  async function getDocData() {
    const docSpecSummaryResponse = await axios.get(
      postRequestUrlPrefix + `doc/get_spec_summary/${documenId}`
    );
    const proposedProdDataResponse = await axios.get(
      postRequestUrlPrefix + `doc/propose_product/${documenId}`
    );
    const curDocData = await axios.get(
      postRequestUrlPrefix + `doc/${documenId}`
    );

    setDocSpecSummary(docSpecSummaryResponse.data.spec_summary);
    setProposeProdData(proposedProdDataResponse.data);
    setDocData(curDocData.data.document);
  }

  useEffect(() => {
    const jsonString = JSON.stringify(jsonSchema);
    if (isJsonValidSchema(jsonString)) {
      validateJsonSchema(jsonSchema);
    } else {
      console.error("Invalid JSON syntax: Missing braces or commas.");
    }
  }, []);

  useEffect(() => {
    getDocData();
  }, []);

  useEffect(() => {
    if (
      !docSpecSummary ||
      !proposeProdData ||
      !proposeProdData?.gtp ||
      !proposeProdData?.comparison_deviations
    ) {
      return;
    }

    const GtpTableRows = () => {
      return proposeProdData?.gtp.map((item) => (
        <tr key={item.id || item.keyword || Math.random()}>
          {jsonSchema.properties.keyword.enabled && <td>{item.keyword}</td>}
          {jsonSchema.properties.extractedInfo.enabled && (
            <td>{item.query_answer || "NOT PRESENT IN DOCUMENT"}</td>
          )}
          {jsonSchema.properties.extractInstruction.enabled && (
            <td>{item.extract_instruction}</td>
          )}
        </tr>
      ));
    };

    const DeviationTableRows = () => {
      return proposeProdData?.gtp.map((item) => (
        <tr key={item.id || item.keyword || Math.random()}>
          {jsonSchema.properties.query.enabled && <td>{item.query}</td>}
          {jsonSchema.properties.keyword.enabled && <td>{item.keyword}</td>}
          {jsonSchema.properties.extractedInfo.enabled && (
            <td>{item.query_answer || "NOT PRESENT IN DOCUMENT"}</td>
          )}
          {jsonSchema.properties.actualValueOfProposedProduct.enabled && (
            <td>{item.gtp_value}</td>
          )}
          {jsonSchema.properties.extractInstruction.enabled && (
            <td>{item.extract_instruction}</td>
          )}
        </tr>
      ));
    };

    const comparisonDeviations = Object.entries(
      proposeProdData?.comparison_deviations
    ).map(
      ([key, value]) =>
        `${key} has query_value ${value.query_value}${value.unit} with actual value ${value.product_value}${value.unit} having deviation of ${value.deviation}`
    );

    const getUniqueValues = (items, key) => {
      return [...new Set(items.map((item) => item[key]))].join(", ");
    };

    const renderCell = (enabled, content) => {
      return enabled ? <td>{content}</td> : null;
    };

    const TableRows = () => {
      return docSpecSummary?.map((item) => {
        // Generate a unique key based on item's properties
        const uniqueKey = `${item.id || item.question}-${
          item.answer || "no-answer"
        }`;
        const pageNumbers = getUniqueValues(item.doc_metadata, "page_number");
        const elementClasses = getUniqueValues(
          item.doc_metadata,
          "element_class"
        );

        return (
          <tr key={uniqueKey}>
            {renderCell(jsonSchema.properties.question.enabled, item.question)}
            {renderCell(
              jsonSchema.properties.answer.enabled,
              item.answer || "NOT PRESENT IN DOCUMENT"
            )}
            {renderCell(jsonSchema.properties.pageNumber.enabled, pageNumbers)}
            {renderCell(
              jsonSchema.properties.elementClasses.enabled,
              elementClasses
            )}
          </tr>
        );
      });
    };

    const initialTabs = [
      <TabItem key="1" title="Spec Summary">
        <div className="table-container">
          <table className="table" id="spec-summary">
            <thead>
              <tr className="sticky-header">
                {jsonSchema.properties.question.enabled && (
                  <th>{jsonSchema.properties.question.description}</th>
                )}
                {jsonSchema.properties.answer.enabled && (
                  <th>{jsonSchema.properties.answer.description}</th>
                )}
                {jsonSchema.properties.pageNumber.enabled && (
                  <th>{jsonSchema.properties.pageNumber.description}</th>
                )}
                {jsonSchema.properties.elementClasses.enabled && (
                  <th>{jsonSchema.properties.elementClasses.description}</th>
                )}
              </tr>
            </thead>
            <tbody>
              <TableRows />
            </tbody>
          </table>
        </div>
        {jsonSchema.properties.downloadXlsOnSpecSummary.enabled && (
          <Button
            className="sticky-btn"
            text={jsonSchema.properties.downloadXlsOnSpecSummary.text}
            onClick={() => downloadXL("spec-summary")}
            shape="pill"
            type="primary-red"
          />
        )}
      </TabItem>,
      <TabItem key="2" title="Deviations" id="deviations">
        <div className="table-container">
          <table className="table" id="deviations-table">
            <thead>
              <tr>
                {jsonSchema.properties.query.enabled && (
                  <th> {jsonSchema.properties.query.description}</th>
                )}
                {jsonSchema.properties.keyword.enabled && (
                  <th>{jsonSchema.properties.keyword.description}</th>
                )}
                {jsonSchema.properties.extractedInfo.enabled && (
                  <th>{jsonSchema.properties.extractedInfo.description}</th>
                )}
                {jsonSchema.properties.actualValueOfProposedProduct.enabled && (
                  <th>
                    {
                      jsonSchema.properties.actualValueOfProposedProduct
                        .description
                    }
                  </th>
                )}
                {jsonSchema.properties.extractInstruction.enabled && (
                  <th>
                    {jsonSchema.properties.extractInstruction.description}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <DeviationTableRows />
            </tbody>
          </table>
        </div>
        {jsonSchema.properties.downloadXlsOnDeviations.enabled && (
          <Button
            text={jsonSchema.properties.downloadXlsOnDeviations.text}
            onClick={() => downloadXL("deviations-table")}
            shape="pill"
            type="primary-red"
          />
        )}
      </TabItem>,
      <TabItem key="3" title="GTP" id="gtp">
        <div className="table-container">
          <table className="table" id="gtp-table">
            <thead>
              <tr>
                {jsonSchema.properties.keyword.enabled && (
                  <th>{jsonSchema.properties.keyword.description}</th>
                )}
                {jsonSchema.properties.extractedInfo.enabled && (
                  <th>{jsonSchema.properties.extractedInfo.description}</th>
                )}
                {jsonSchema.properties.extractInstruction.enabled && (
                  <th>
                    {jsonSchema.properties.extractInstruction.description}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              <GtpTableRows />
            </tbody>
          </table>
        </div>
        {jsonSchema.properties.downloadXlsOnGtp.enabled && (
          <Button
            text={jsonSchema.properties.downloadXlsOnGtp.text}
            onClick={() => downloadXL("gtp-table")}
            shape="pill"
            type="primary-red"
          />
        )}
        {jsonSchema.properties.btnAnnotatedPdf.enabled && (
          <Button
            style={{ marginLeft: "12px" }}
            text={jsonSchema.properties.btnAnnotatedPdf.text}
            // onClick={downloadFile}
            shape="pill"
            type="primary-red"
          />
        )}
      </TabItem>,
      <TabItem key="4" title="Product Proposal">
        <div>
          <div className="table-container">
            <table className="table" id="product-table">
              <thead>
                <tr>
                  {jsonSchema.properties.productName.enabled && (
                    <th>{jsonSchema.properties.productName.description}</th>
                  )}
                  {jsonSchema.properties.productName.enabled && (
                    <th>{jsonSchema.properties.score.description}</th>
                  )}
                  {jsonSchema.properties.comparisonDeviation.enabled && (
                    <th>
                      {jsonSchema.properties.comparisonDeviation.description}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {jsonSchema.properties.productName.enabled && (
                    <td style={{ fontSize: "18px", fontWeight: "600" }}>
                      {proposeProdData?.product_name}
                    </td>
                  )}
                  {jsonSchema.properties.score.enabled && (
                    <td>{proposeProdData?.score}</td>
                  )}
                  {jsonSchema.properties.comparisonDeviation.enabled && (
                    <td>
                      {comparisonDeviations.map((deviation) => (
                        <React.Fragment key={deviation}>
                          {" "}
                          {deviation}
                          <br />
                        </React.Fragment>
                      ))}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          {jsonSchema.properties.btnCadGenerator.enabled && (
            <Button
              text={jsonSchema.properties.btnCadGenerator.text}
              onClick={() => downloadXL("product-table")}
              shape="pill"
              type="primary-red"
            />
          )}
        </div>
      </TabItem>,
    ];

    setTabs(initialTabs);
  }, [docSpecSummary, proposeProdData]);

  const downloadXL = (tableName) => {
    const table = document.getElementById(tableName); // HTML table element
    const workbook = XLSX.utils.table_to_book(table, { sheet: "Sheet1" }); // Create workbook and worksheet
    XLSX.writeFile(workbook, tableName + ".xlsx"); // Write the workbook to file1
  };

  function showDashboard() {
    setcurrentProoferState("dashboard");
  }

  const [tabs, setTabs] = useState([]);

  return (
    <>
      {showDialog && currentProoferState === "create-form" ? (
        <ProoferForm showDialog={showDialog} setShowDialog={setShowDialog} />
      ) : (
        ""
      )}

      <div className="proofer-header-container">
        <div className="col-5 col-m-12 col-s-12">
          {jsonSchema.properties.summary.enabled && (
            <div className="filter-header">
              {jsonSchema.properties.summary.description}
            </div>
          )}
        </div>
        <div className="col-7 col-m-12 col-s-12">
          {/* {startTS} To {endTS} */}
          <div className="filters-group row">
            <Button
              text="Dashboard"
              onClick={() => showDashboard()}
              shape="pill"
              type="primary-red"
            />
          </div>
        </div>
      </div>

      <div className="output">
        <div>
          {jsonSchema.properties.oppurtinityNo.enabled && (
            <div className="col-4">
              <b>{jsonSchema.properties.oppurtinityNo.description}</b>{" "}
              {docData?.metadata.oppno}
            </div>
          )}
          <div className="col-4"></div>
          {jsonSchema.properties.fileName.enabled && (
            <div className="col-4">
              <b>{jsonSchema.properties.fileName.description}</b>{" "}
              {docData?.metadata.filename}
            </div>
          )}
        </div>
        <div>
          {jsonSchema.properties.customerName.enabled && (
            <div className="col-4">
              <b>{jsonSchema.properties.customerName.description}</b>{" "}
              {docData?.metadata.customername}
            </div>
          )}
          {jsonSchema.properties.project.enabled && (
            <div className="col-4">
              <b>{jsonSchema.properties.project.description}</b>{" "}
              {docData?.metadata.project}
            </div>
          )}
          {jsonSchema.properties.tenderState.enabled && (
            <div className="col-4">
              <b>{jsonSchema.properties.tenderState.description}</b>{" "}
              {docData?.metadata.state}
            </div>
          )}
        </div>
        <div>
          {jsonSchema.properties.productGroup.enabled && (
            <div className="col-4">
              <b>{jsonSchema.properties.productGroup.description}</b>{" "}
              {docData?.metadata.productgroup}
            </div>
          )}
          {jsonSchema.properties.createdOn.enabled && (
            <div className="col-4">
              <b>{jsonSchema.properties.createdOn.description}</b>{" "}
              {docData?.metadata.createdon}
            </div>
          )}
          {jsonSchema.properties.opportunityId.enabled && (
            <div className="col-4">
              <b>{jsonSchema.properties.opportunityId.description}</b>{" "}
              {docData?.metadata.id}
            </div>
          )}
        </div>
        <TabControl
          className="ABB_CommonUX_TabControl__dark"
          type={type}
          activeTab={activeTab}
          onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}
          style={{ width: "100%", height: "500px", padding: "20px !important" }}
        >
          {tabs}
        </TabControl>
      </div>
    </>
  );
};

export default ProoferTabs;
