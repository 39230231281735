import React, { useContext, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { RootContext } from "../../../contexts/root-context";
import "./energy-mix.css";
import { Spinner } from "@abb/abb-common-ux-react";

function EnergyMix() {
  const {
    powerTrendsData,
    energyMixSelectedDuration,
  } = useContext(RootContext);
  const energyMixMeasures = powerTrendsData?.energy_trend || [];
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [isSpin, setIsSpin] = useState(true);

  useEffect(() => {
    updateEnergyMixChartData();
  }, [energyMixMeasures, energyMixSelectedDuration]);

  const updateEnergyMixChartData = () => {
    if (!Array.isArray(energyMixMeasures) || energyMixMeasures.length === 0) {
      setData([]);
      return;
    }

    // Find the selected data based on energyMixSelectedDuration
    const selectedData = energyMixMeasures.find(
      (item) => item.name === energyMixSelectedDuration
    );
    if (!selectedData) {
      setIsSpin(true);
      return;
    }
    // Format datetime values to display only hours and minutes
    const formattedDatetime = selectedData.value.map((item) => {
      const dateObj = new Date(item.datetime);
      return `${dateObj.getHours().toString().padStart(2, "0")}:${dateObj
        .getMinutes()
        .toString()
        .padStart(2, "0")}`;
    });
    setData({ formattedDatetime, selectedData });
    setIsSpin(false);
  };

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const chart = echarts?.init(chartRef?.current);

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        orient: "horizontal",
        top: "bottom",
      },
      xAxis: {
        type: "category",
        data: data.formattedDatetime,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          padding: -2,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          padding: 0,
          verticalAlign: "top",
          lineHeight: 10,
        },
        name: "Energy (kWh)",
        nameLocation: "center",
        nameGap: 35,
        color: "#ebebeb",
      },
      series: [
        {
          name: "Utility",
          type: "bar",
          data: data.selectedData?.value.map((item) => item.utility),
          itemStyle: {
            color: "#808080",
          },
          stack: "x",
        },
        {
          name: "Solar",
          type: "bar",
          data: data.selectedData?.value.map((item) => item.solar),
          itemStyle: {
            color: "#008000",
          },
          stack: "x",
        },
      ],
    };

    chart.setOption(option);
    window.addEventListener("resize", chart.resize);

    return () => {
      window.removeEventListener("resize", chart.resize);
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {energyMixMeasures?.length > 0 ? (
            <div className="energy-mix-container">
              <div
                ref={chartRef}
                className="resizable-chart"
              />
            </div>
          ) : (
            <p className="center-text-error"></p>
          )}
        </>
      )}
    </>
  );
}

export default EnergyMix;
