import React, { useContext, useEffect, useState } from "react";
import { Gauge } from "@abb/abb-common-ux-react";
import "./actual-power.css";
import { RootContext } from "../../../contexts/root-context";

function ActualPower() {
  const { siteKpis } = useContext(RootContext);

  const actualPower = siteKpis?.siteKPIMetrics?.actualPower?.value;
  const [value, setValue] = useState(actualPower || 35);
  const [range] = useState([0, 500]);
  const [decimals] = useState(0);
  const [warnings] = useState([125, 375]);
  const [alarms] = useState([50, 450]);
  const [limitMarkerStyle] = useState("none");

  // Update value state when valueForSolarTarget changes
  useEffect(() => {
    if (actualPower !== undefined) {
      setValue(actualPower);
    }
  }, [actualPower]);

  return (
    <div
      className="kpi-gauge-content"
      style={{
        width: "100%",
      }}
    >
      <Gauge
        className="nz-gauge-style"
        type="radial"
        min={range[0]}
        max={range[1]}
        minLabel={" "}
        maxLabel={" "}
        lowAlarm={alarms[0]}
        highAlarm={alarms[1]}
        lowWarn={warnings[0]}
        highWarn={warnings[1]}
        unit="kWh"
        value={value}
        limitStyle={limitMarkerStyle}
        decimals={decimals}
      />
    </div>
  );
}

export default React.memo(ActualPower);
