import React from "react";
import DisturbanceEvents from "../../components/Bar-Disturbance-Events/disturbance-events";
import Card from "../../components/Card/card";
import PowerMeasurement from "../../components/Gauge-Power-Measurement/power-measurement";
import DisturbanceMeasurementData from "../../components/Disturbance-Records/disurbance-measurements-datagrid";
import "./widgets.css";
import CriticalAlerts from "../../components/Gauge-Critical-Alerts/critical-alerts";
import AlarmSummary from "../../components/Donut-Alarm-Summary/alarm-summary";
import EventLog from "../../components/Donut-Event-Log/event-log";
import FaultDuration from "../../components/Donut-Fault-Duration/fault-duration";
import FeederMeasurement from "../../components/Feeder-Measurement/feeder-measurement";
import OperationStatus from "../../components/Operation-Status/operation-status";
import AlarmNotificationsData from "../../components/Alarm-Notifications/alarm-noti-datagrid";
import CurrentTrend from "../../components/Line-Current-Trend/current-trend";
import VoltageTrend from "../../components/Line-Voltage-Trend/voltage-trend";
// import KPIChart1 from "../../components/KPIMentrics/kpi-metrics";
// import KPIChart from "../../components/Widget_1/score";
// import KPIChart3 from "../../components/Custom-Chart-3/custom-bar";
// import KPIChart4 from "../../components/Custom-Chart-4/custom-chart-4";
function Widgets() {
  return (
    <>
      <div className="widget-container">
        {/* {
        cardItem.map((cardValue,cardItem)=>{
            return <Card>{cardValue}</Card>
        })
        
        } */}

        <Card
          widgetName="Operation Status"
          widgetUpdateTime="2"
          component={<OperationStatus />}
          applystyle="true"
        ></Card>

        <Card
          widgetName="Critical Alerts"
          widgetUpdateTime="2"
          component={<CriticalAlerts />}
          applystyle="true"
        ></Card>
        <Card
          widgetName="Alarm Summary"
          widgetUpdateTime="2"
          component={<AlarmSummary />}
        ></Card>
        <Card
          widgetName="Alarm Notifications"
          widgetUpdateTime="2"
          component={<AlarmNotificationsData />}
        ></Card>

        <Card
          widgetName="Feeder Measurements"
          widgetUpdateTime="2"
          component={<FeederMeasurement />}
        ></Card>
        <Card
          widgetName="Current Trend"
          widgetUpdateTime="2"
          component={<CurrentTrend />}
        ></Card>
        <Card
          widgetName="Voltage Trend"
          widgetUpdateTime="2"
          component={<VoltageTrend />}
        ></Card>
        <Card
          widgetName="Power Measurement"
          widgetUpdateTime="2"
          component={<PowerMeasurement />}
        ></Card>

        <Card
          widgetName="Disturbance Events"
          widgetUpdateTime="2"
          component={<DisturbanceEvents />}
        ></Card>
        <Card
          widgetName="Disturbance Records"
          widgetUpdateTime="2"
          component={<DisturbanceMeasurementData />}
        ></Card>
        <Card
          widgetName="Event Log By Function"
          widgetUpdateTime="2"
          component={<EventLog />}
        ></Card>
        <Card
          widgetName="Fault Duration"
          widgetUpdateTime="2"
          component={<FaultDuration />}
        ></Card>
      </div>
    </>
  );
}

export default Widgets;
