import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import "./power-demand.css"
import powerDemand from './power-demand.json';
import { validateJson, isJsonValid } from '../../util';

const PowerDemand = () => {
    const chartRef = useRef(null);
    const myChart = useRef(null);

        useEffect(() => {
        const jsonString = JSON.stringify(powerDemand); // Convert the imported JSON data to string
         
        if (isJsonValid(jsonString)) {
          validateJson( powerDemand,powerDemand.schema); // If valid, validate the schema
        } else {
          console.error('Invalid JSON syntax: Missing braces or commas.');
        }
      }, []);

    useEffect(() => {
        myChart.current = echarts.init(chartRef.current);
            const option = {
                series: [
                    {
                        type: powerDemand.series.type,
                        center: powerDemand.series.center,
                        startAngle: powerDemand.series.startAngle,
                        endAngle: powerDemand.series.endAngle,
                        min: powerDemand.series.min,
                        max: powerDemand.series.max,
                        progress: {
                            show: powerDemand.series.progress.show,
                            width: powerDemand.series.progress.width,
                            itemStyle: {
                                color: powerDemand.series.progress.itemStyle.color, // Color for the data range
                            },
                            backgroundStyle: {
                                color: powerDemand.series.progress.backgroundStyle.color // Color for the data track (background)
                            }
                        },
                        detail: {
                            formatter: '13%\nPower Demand',
    
                            offsetCenter: [0, 0],// Adjust offsetCenter to center the value text
    
                            textStyle: { // Add textStyle to modify font size
                                fontSize: 18, // Adjust the font size here
    
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: powerDemand.series.axisLine.lineStyle.width, // Set axis line width to 0 to hide it
                            }
                        },
                        axisLabel: {
                            show: powerDemand.series.axisLabel.show, // Hide axis labels
                        },
                        axisTick: {
                            show: powerDemand.series.axisTick.show, // Hide axis ticks
                        },
                        splitLine: {
                            show: powerDemand.series.splitLine.show, // Hide split lines
                        },
                        pointer: {
                            show: powerDemand.series.pointer.show, // Hide pointer
                        },
                        data: [
                            {value: 13}
                        ],
                    }
                ]
            };

            myChart.current.setOption(option);

    }, []);

    return (
        <div className='power-container'>
            <div className='chart-holder'>
                <div className='chart-container' ref={chartRef}></div>
            </div>
            <div className='power-info'>
                <div className='power-inner'>
                    {powerDemand.powerMetrics.map((metric, index) => (
                                            <div className='power-metrics'  key={`metric-${metric.key}-${index}`}>
                                            <span>{metric.label}</span>
                                            <span style={{ "fontSize": "16px", "fontWeight": "bold" }}>{metric.value}</span>
                                        </div>

                    ))}
                </div>
            </div>
        </div>
    )
};

export default PowerDemand;


