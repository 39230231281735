import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import * as echarts from 'echarts';
import { Dropdown, DropdownOption,Checkbox  } from '@abb/abb-common-ux-react';
import PeriodList from '../Period-Dropdown-list/period-dropdown-list';
import "./energy-monitoring.css"
import chartData from './chartData.json'
import energyMonitoringData from './energy-monitoring.json'
import { validateJson, isJsonValid } from '../../util';
import { INVALID_JSON_SYNTAX } from './string.js';
const EnergyMonitorBar = () => {
    const chartRef = useRef(null);

   
    const validateEnergyMonitoringData=useCallback(()=>{
        const jsonString = JSON.stringify( energyMonitoringData); 
         
        if (isJsonValid(jsonString)) {
          validateJson( energyMonitoringData,energyMonitoringData.energyMonitoringSchema); 
        } else {
          
            console.error(INVALID_JSON_SYNTAX);
 
        }
      }, [energyMonitoringData]);
   

    useEffect(() => {
        validateEnergyMonitoringData();

    }, [validateEnergyMonitoringData]);
 


    const chatOptions=useMemo(()=>{
        const data = chartData.data;
        return{
            tooltip: {
                trigger: energyMonitoringData.tooltip.trigger,
                axisPointer: {
                    type: energyMonitoringData.tooltip.axisPointer.type,
                }
            },
            xAxis: {
                type: energyMonitoringData.xAxis.type,
                data: data.map(item => item.name),
                axisTick: {
                    alignWithLabel: true
                },
                name: energyMonitoringData.xAxis.name,
                axisLabel: {
                    padding: energyMonitoringData.xAxis.axisLabel.padding,

                },
                nameLocation: energyMonitoringData.xAxis.nameLocation,
                nameGap: 30
            },
            yAxis: {
                type: energyMonitoringData.yAxis.type,
                axisLabel: {
                    padding:  energyMonitoringData.yAxis.axisLabel.padding,
                    verticalAlign:  energyMonitoringData.yAxis.axisLabel.verticalAlign, // Aligns the labels towards the top
                    lineHeight:  energyMonitoringData.yAxis.axisLabel.lineHeight, // Adjust the line height as needed
                },
                name:  energyMonitoringData.yAxis.name,
                nameLocation:  energyMonitoringData.yAxis.nameLocation,
                nameRotate:  energyMonitoringData.yAxis.nameRotate,
                nameGap: 30,  
            },
            series: [
               
                {
                    name: energyMonitoringData.series[0].name,
                    type:  energyMonitoringData.series[0].type,
                    data: data.map(item => item.value1),
                    itemStyle: {
                        color: '#FF4754'
                    }
                },
                {
                    name:  energyMonitoringData.series[1].name,
                    type: energyMonitoringData.series[1].type,
                    data: data.map(item => item.value2),
                    itemStyle: {
                        color: '#F2AC66'
                    }
                }],
        };
    
    },[energyMonitoringData ,chartData]);
    useEffect(() => {
        
        const chart = echarts.init(chartRef.current);
        chart.setOption(chatOptions);

        return () => {
            chart.dispose();
        };
    }, [chatOptions]);


    const dropdowns=useMemo(()=>{
        return energyMonitoringData.dropdowns.map((dropdown, index) => (
            <Dropdown 
            key={`dropdown-${dropdown.key}-${index}`} label={dropdown.label} placeholder={dropdown.placeholder} >
                {dropdown.options.map(option => (
                    <DropdownOption key={option.key} label={option.label} value={option.value} />
                ))}
            </Dropdown>
        ));
    } ,[energyMonitoringData.dropdowns])

    return (
        <div className='energy-monitoring' >
            <div className='indicator-container-line'>

                 {dropdowns}

                <PeriodList />
                <Checkbox style={{ paddingTop: "35px" }}  label={energyMonitoringData.checkbox.label}  />

            </div>
            <div ref={chartRef} className='energy-moni-chart' style={{}} />
        </div>

        

    );
};

export default EnergyMonitorBar;
