import React, { useContext, useEffect, useState } from "react";
import {
    TabItem,
    TabControl,
    Button,
    Datagrid,
    Dialog,
    Spinner,
    Icon,
    WithPopup,
    Popup,
} from "@abb/abb-common-ux-react";
import { useLocation, useNavigate } from "react-router-dom";
import "./input-parameters.css";
import { RootContext } from "../../contexts/root-context";
import ObjectiveFunction from "../../components/Microgrid-Opt-Components/Input-settings/objective-function-tab/objective-fun";
import NetworkParams from "../../components/Microgrid-Opt-Components/Input-settings/network-params-tab/network-params";

const InputParameterSettings = () => {
    const { setisInputSettingClicked } = useContext(RootContext);

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = React.useState(0);
    const goToDashboard = () => {
        setisInputSettingClicked(false);
        navigate("../microgrid-opt");

    };
    const initialTabs = [
        <TabItem key="1" title="Network Parameters">
            <NetworkParams />
        </TabItem>,
        <TabItem key="2" title="Objective Function">
            <ObjectiveFunction />
        </TabItem>]
    const [tabs, setTabs] = React.useState(initialTabs);
    const [type, setType] = React.useState('primary');
    const [monochrome, setMonochrome] = React.useState(false);

    return (
        <>
            <div className="settings-page-content">
                <div className="row">
                    <span className="go-back" onClick={goToDashboard}>
                        <Icon sizeClass="small" name="abb/left-arrow" />
                        {"  "}Back
                    </span>
                </div>
                <div className="row">
                    <span className="opt-input" >
                        Optimization Input
                    </span>
                </div>
                <div className="row">
                    <TabControl
                        type={type}
                        id="mg-input-params-tabs-container"

                        activeTab={activeTab}
                        onTabChange={(oldIndex, newIndex) => setActiveTab(newIndex)}

                        monochrome={monochrome}
                    // style={{ maxWidth: '500px' }}
                    >
                        {tabs}
                    </TabControl>
                </div>
            </div>
        </>
    )
}
export default InputParameterSettings; 