import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import NetZeroCard from "../../components/Net-Zero-Components/Net-Zero-Card/net-zero-card";
import { NZ_ERROR_LIST, SITE_KPIS_CARD_JSON } from "../../strings";
import "./site-kpis.css";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import { Link, Spinner } from "@abb/abb-common-ux-react";

function SiteKPIs() {
  const { setSiteKpis } = useContext(RootContext);
  const [data, setData] = useState({});
  const [isSpin, setIsSpin] = useState(true);
  const [error, setError] = useState(false);

  const fetchSiteKPIsData = useCallback(async () => {
    setIsSpin(true);
    try {
      const response = await axios.get(
        "https://bzxhlhpxj7.execute-api.us-east-1.amazonaws.com/api/kpi"
      );
      setData(response.data);
      setSiteKpis(response.data);
      setError(false);
    } catch (error) {
      console.error("Failed to fetch KPI data:", error);
      setError(true);
    } finally {
      setIsSpin(false);
    }
  }, [setSiteKpis]);

  useEffect(() => {
    fetchSiteKPIsData();
    const interval = setInterval(fetchSiteKPIsData, 15 * 60 * 1000);

    return () => clearInterval(interval);
  }, [fetchSiteKPIsData]);

  const siteName = useMemo(
    () => data?.siteMetadata?.siteName || "ABB BUILDING 1",
    [data]
  );

  const [leftWidgetsJson, rightWidgetsJson] = useMemo(() => [
    SITE_KPIS_CARD_JSON.slice(0, 9),
    SITE_KPIS_CARD_JSON.slice(9),
  ], []);

  const leftWidgets = useMemo(
    () =>
      leftWidgetsJson.map((widget, index) => (
        <NetZeroCard
          key={widget.widgetName}
          widgetName={index === 0 ? siteName : widget.widgetName}
          widgetType={widget.widgetType}
          nzcomponent={widget.nzcomponent}
          enabled={widget.enabled}
        />
      )),
    [leftWidgetsJson, siteName]
  );

  const rightWidgets = useMemo(
    () =>
      rightWidgetsJson.map((widget) => (
        <NetZeroCard
          key={widget.widgetName}
          widgetName={widget.widgetName}
          widgetType={widget.widgetType}
          nzcomponent={widget.nzcomponent}
          enabled={widget.enabled}
        />
      )),
    [rightWidgetsJson]
  );

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {NZ_ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link style={{ color: "#1f1f1f" }} onClick={fetchSiteKPIsData}>
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data || Object.keys(data).length > 0 ? (
                <div className="kpi-container">
                  <div className="kpi-left-div">{leftWidgets}</div>
                  <div className="kpi-right-div">{rightWidgets}</div>
                </div>
              ) : (
                <p className="center-text-error">{NZ_ERROR_LIST.NO_DATA}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default React.memo(SiteKPIs);
