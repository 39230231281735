import React, { useMemo } from "react";
import "./net-zero-card.css";

const getCardSizeClass = (type) => {
  switch (type) {
    case "small":
      return "small-card";
    case "medium":
      return "medium-card";
    case "large":
      return "large-card";
    case "extra-large":
      return "extra-large-card";
      case "trends":
        return "trends-card";
    default:
      return "";
  }
};

const NetZeroCard = (props) => {
  const { widgetName, widgetType, nzcomponent, dropdownComponent, enabled } = props;
  
  const cardSizeClass = useMemo(
    () => getCardSizeClass(widgetType),
    [widgetType]
  );

  if (!enabled) return null;

  return (
    <div className={`chart-card-${cardSizeClass}`}>
      <div className="chart-header">
        <div className="chart-title">{widgetName}</div>
        {cardSizeClass === "trends-card" && (
          <div className="card-content-dropdown">{dropdownComponent}</div>
        )}
      </div>
      <hr />
      <div className="nz-card-content">{nzcomponent}</div>
    </div>
  );
};

export default NetZeroCard;
