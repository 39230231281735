import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import ABBLogin from "./pages/login/login";
import Dashboard from "./pages/dashboard/dashboard";
import ChangePassword from "./pages/change-password/change-passoword";
import Signup from "./pages/signup/signup";
import NetZeroDashboard from "./pages/net-zero";
import MicrogridOpti from "./pages/micro-grid-opt";
import InputParameterSettings from "./pages/micro-grid-opt/input-parameters";
import ProoferTool from "./pages/proofer-pages";
import ProoferDashboard from "./pages/proofer-pages/dashboard/dashboard";
import ProoferTabs from "./components/proofer-tool-cmps/popup-output-tabs/popup-output-tabs";
import NetworkPreview from "./components/Microgrid-Opt-Components/Network/network-preview";

function CogniENRoutes() {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<ABBLogin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/" element={<Dashboard />} />
        <Route path="/highcharts" element={<Dashboard />} />
        <Route path="/em" element={<Dashboard />} />
        <Route path="/change-password" element={<ChangePassword />}
        />
        <Route exact path="/signup" element={<Signup />} />
        {/* <Route exact path="/signup-success" element={<SignupSucces />} />
        <Route exact path="/signup-verified" element={<SignUpPostVerification />} /> */}
        <Route path="/nz" element={<NetZeroDashboard />} />

        <Route path="/microgrid-opt" element={<MicrogridOpti />} >
          <Route path="/microgrid-opt/input-settings" element={<InputParameterSettings />} />
        </Route>
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/network-preview" element={<NetworkPreview />} />
        
        {/* Proofer tool */}
        <Route path="/proofer-tool" element={<ProoferTool />} >
          <Route path="/proofer-tool/popup-output-tabs" element={<ProoferTabs />} />
        </Route >
        {/* <Route path="/proofer-tool" element={<ProoferTool />} >
          <Route path="/proofer-tool/proofer-dashboard" element={<ProoferDashboard />} />
        </Route> */}
        
      </Routes >
    </HashRouter >
  );
}

export default CogniENRoutes;
