import React from "react";
import "./widgets.css";
import EnergyData from "../../components/Energy-Data/energy-data";
import EngergyCostCmp from "../../components/Energy-Cost-Cmp/energy-cost-datagrid";
import EnergyAuditSummary from "../../components/Energy-Audit-Summary/energy-audit-summary";
import EnergyMonitorBar from "../../components/Energy-Monitoring/energy-monitoring";
import GroupRealtimePower from "../../components/Group-Realtime-Power/energy-cost-datagrid";
import PeakMonitoring from "../../components/Peak-Monitoring/peak-monitoring";
import GaugePowerGenerator from "../../components/Power-Generation/power-generation";
import Card3 from "../../components/EMCard/em-card";
import EnergyCostComp from "../../components/Custom-Chart-5/energy-datagrid";
import ENPI_MONITORING from "../../components/Enpi-Monitoring/enpi-monitoring";
import PowerDemand from "../../components/Power-Demand/power-demand";
import KPIChart from "../../components/KPI-Chart/kpi-chart";
function EchartsWidgets() {
    return (
        
            <div className="widget-container">
             

                <Card3
                    widgetName="Energy Performance Indicator"
                    widgetUpdateTime="2"
                    component={<KPIChart />}
                    applyScroll="true"
                ></Card3>
                <Card3
                    widgetName="Energy Monitoring"
                    widgetUpdateTime="2"
                    component={<EnergyMonitorBar />}
                    applyScroll="true"
                ></Card3>
                <Card3
                    widgetName="Energy Data"
                    widgetUpdateTime="2"
                    component={<EnergyData />}
                    applyScroll="true"
                ></Card3>
                <Card3
                    widgetName="Energy Cost Comparison"
                    widgetUpdateTime="2"
                    isTimePeriod={true}
                    component={<EnergyCostComp />}
                    applystyle="true"
                ></Card3>

                <Card3
                    widgetName="EnPI Monitoring"
                    widgetUpdateTime="2"
                    component={<ENPI_MONITORING />}
                    applyScroll="true"
                ></Card3>
                <Card3
                    widgetName="Power Demand"
                    widgetUpdateTime="2"
                    component={<PowerDemand />}
                    applyScroll="true"
                ></Card3>
                <Card3
                    widgetName="Power Generation"
                    widgetUpdateTime="2"
                    component={<GaugePowerGenerator />}
                    applyScroll="true"
                ></Card3>


                <Card3
                    widgetName="Peak Monitoring"
                    widgetUpdateTime="2"
                    component={<PeakMonitoring />}
                    applyScroll="true"
                ></Card3>
                <Card3
                    widgetName="Energy Data Comparison"
                    widgetUpdateTime="2"
                    component={<EngergyCostCmp />}
                    applystyle="true"
                ></Card3>

                <Card3
                    widgetName="Group Realtime Power"
                    widgetUpdateTime="2"
                    component={<GroupRealtimePower />}
                    applystyle="true"
                ></Card3>
                <Card3
                    widgetName="Energy Audit Summary"
                    widgetUpdateTime="2"
                    component={<EnergyAuditSummary />}
                    applystyle="true"
                ></Card3>


            </div>
       
    );
}

export default EchartsWidgets;
