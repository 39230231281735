import React, { useContext } from "react";
import Widgets from "./widgets";
import Filters from "../../components/Filters/filters";
import Navbar from "../../components/Navbar/navbar";
import { RootContext } from "../../contexts/root-context";
import NotificationExample from "../../components/Notification/notification";
import UserSession from "../../components/Authentication/user-session";
import EchartsWidgets from "./echarts";

function Dashboard() {
  const { isnotify } = useContext(RootContext);
  
  return (
    <>
      {isnotify === "true" ? <NotificationExample notitype={isnotify} /> : ""}
    
      {isnotify === "error" ? <NotificationExample notitype={isnotify} /> : ""}

      <UserSession />
      <Navbar />
      <Filters />
      {/* <EchartsWidgets /> */}
      {/*if there is no other widgets then call directly <Widgets />*/}
      {localStorage.getItem("current-page") === "dashboard" || localStorage.getItem("current-page") === null ? <Widgets /> :
        localStorage.getItem("current-page") === "em" ? <EchartsWidgets /> : ""}
    </>
  );
}
export default Dashboard;
