import React, { useEffect } from "react";
import { DropdownOption, Dropdown } from "@abb/abb-common-ux-react";


import "./kpi-chart.css";
import PeriodList from "../Period-Dropdown-list/period-dropdown-list";

import kpiData from './kpi-chat-schema.json'
import { validateJson, isJsonValid } from '../../util';

import { INVALID_JSON_SYNTAX } from './string.js';
const KPIChart = () => {


  useEffect(() => {
    const jsonString = JSON.stringify(kpiData);
  
    const validationResult = isJsonValid(jsonString);
    
    if (validationResult === true) {
      validateJson(kpiData,kpiData.kpiSchema);
    } else {
      console.error(INVALID_JSON_SYNTAX);  // Log the actual error message
    }


  }, [kpiData]); 
  



  return (
    <>
      <div style={{ width: "100%" }}>


        <div className="indicator-container-top">

        
         {kpiData.dropdowns.map((dropdown, index) => (
                        <Dropdown
                        key={index}
                        style={{ visibility: dropdown.visibility }}
                        label={dropdown.label}
                        placeholder={dropdown.placeholder}
                        >
                        {dropdown.options.map(option => (
                            <DropdownOption 
                            key={option.key} 
                            label={option.label} 
                            value={option.value} 
                            />
                        ))}
                        </Dropdown>
                    ))}


          <PeriodList />
        </div>


        <div style={{ height: "190px" }}>
          <span>{kpiData.summary.label}</span>
          <h1>{kpiData.summary.value1},{kpiData.summary.value2}</h1>
          <span>
            {kpiData.summary.unit}
          </span>
        </div>

         <div className="indicator-container">
          <div className="inside-metrics">
            <span className="im1">{kpiData.metrics[0].title}</span>
            <span className="im2">{kpiData.metrics[0].unit}</span>
            <span className="im3">{kpiData.metrics[0].numerator}</span>
            <span className="im4">{kpiData.metrics[0].denominator}</span>
          </div>
        </div>

        <hr className="ind-line" />

        <div className="indicator-container">
          <div className="inside-metrics">
            <span className="im1">{kpiData.metrics[1].title}</span>
            <span className="im2">{kpiData.metrics[1].unit}</span>
            <span className="im3">{kpiData.metrics[1].numerator}</span>
            <span className="im4">{kpiData.metrics[1].denominator}</span>
          </div>
        </div> 



 </div>



    </>
  );
};

export default KPIChart;
