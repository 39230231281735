import React, { useState, useEffect } from 'react';
import { Dialog, LoginScreen } from "@abb/abb-common-ux-react";
import "./welcome.css";
import ProoferDashboard from '../../../pages/proofer-pages/dashboard/dashboard';
import UserSession from "../../../components/Authentication/user-session";
import Navbar from '../../Navbar/navbar';
import jsonSchema from "./welcome-scheme.json";
import { isJsonValidSchema, validateJsonSchema } from "../../../util.js";

const Welcome = () => {
    const [loading] = useState(true);
    const [monochrome] = useState(false);
    const [showLogo] = useState(true);
    const [showDialog, setShowDialog] = useState(true); // State to control dialog visibility

    useEffect(() => {
        const jsonString = JSON.stringify(jsonSchema)
        if (isJsonValidSchema(jsonString)) {
          console.log('JSON being validated:', jsonSchema);
          validateJsonSchema(jsonSchema); // Pass ignored properties
        } else {
          console.error('Invalid JSON syntax: Missing braces or commas.');
        }
      }, []);

    useEffect(() => {
      
        const timer = setTimeout(() => {
            setShowDialog(false);
        }, 5000);

        return () => clearTimeout(timer); // Cleanup the timer
    }, []);

    return (
 
        <>
            <UserSession />

            {showDialog ? (
                <>
                    <Navbar />
                    <Dialog disableDefaultStyle={true} id='welcome-screen'>
                        <LoginScreen
                            className="ABB_CommonUX_LoginScreen__root ABB_CommonUX_LoginScreen__dark ABB_CommonUX_LoginScreen__loading"
                            productName={jsonSchema.properties.title.text}
                            productSubName={jsonSchema.properties.subtitle.text}
                            loading={loading}
                            monochrome={monochrome}
                            showLogo={showLogo}
                            showDefaultFields={false}
                            showLoginButton={false}
                        />
                    </Dialog>
                </>
            ) : (


                < ProoferDashboard />

            )}
        </>

    );
};

export default Welcome;
