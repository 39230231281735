import Ajv from "ajv";

export function getDefaultTimeStamp(minutes = 60) {
  if (isNaN(minutes)) {
    console.log("Invalid Time Selected");
    return null;
  }

  if (!isNaN(minutes)) {
    let startDate = new Date();
    let currentDate = `${(startDate.getMonth() + 1 + "").padStart(2, "0")}/${(
      startDate.getDate() + ""
    ).padStart(2, "0")}/${startDate.getFullYear()} ${(
      startDate.getHours() + ""
    ).padStart(2, "0")}:${(startDate.getMinutes() + "").padStart(2, "0")}:${(
      startDate.getSeconds() + ""
    ).padStart(2, "0")}`;
    //One minute ago...
    let dd = startDate.setMinutes(startDate.getMinutes() - minutes);
    //Display the date:
    let date = new Date(dd);
    let day = date.getDate();
    let monthIndex = date.getMonth() + 1;
    let hr = date.getHours();
    let mn = date.getMinutes();
    let sc = date.getSeconds();
    let year = date.getFullYear();
    let minsSubStractDate = `${(monthIndex + "").padStart(2, "0")}/${(
      day + ""
    ).padStart(2, "0")}/${(year + "").padStart(2, "0")} ${(hr + "").padStart(
      2,
      "0"
    )}:${(mn + "").padStart(2, "0")}:${(sc + "").padStart(2, "0")}`;

    let timestamps = {
      endDate: currentDate,
      startDate: minsSubStractDate,
    };
    return timestamps;
  } else {
    return console.log("Invalid Time Selected");
  }
}
export default function getTimestamps(minutes) {
  if (!isNaN(minutes)) {
    let startDate = new Date();
    let currentDate = `${(startDate.getMonth() + 1 + "").padStart(2, "0")}/${(
      startDate.getDate() + ""
    ).padStart(2, "0")}/${startDate.getFullYear()} ${(
      startDate.getHours() + ""
    ).padStart(2, "0")}:${(startDate.getMinutes() + "").padStart(2, "0")}:${(
      startDate.getSeconds() + ""
    ).padStart(2, "0")}`;

    let dd = startDate.setMinutes(startDate.getMinutes() - minutes);
 
    let date = new Date(dd);
    let day = date.getDate();
    let monthIndex = date.getMonth() + 1;
    let hr = date.getHours();
    let mn = date.getMinutes();
    let sc = date.getSeconds();
    let year = date.getFullYear();
    let minsSubStractDate = `${(monthIndex + "").padStart(2, "0")}/${(
      day + ""
    ).padStart(2, "0")}/${(year + "").padStart(2, "0")} ${(hr + "").padStart(
      2,
      "0"
    )}:${(mn + "").padStart(2, "0")}:${(sc + "").padStart(2, "0")}`;
  
    let timestamps = {
      endDate: currentDate,
      startDate: minsSubStractDate,
    };
    return timestamps;
  } else {
    return console.log("Invalid Time Selected");
  }
}

export  function getEmCoreTimestamps(minutes) {
  
  if (!isNaN(minutes)) {
    let startDate = new Date('2015-12-24 00:00:00');
    let currentDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1 + "").padStart(2, "0")}-${(
      startDate.getDate() + ""
    ).padStart(2, "0")} ${(
      startDate.getHours() + ""
    ).padStart(2, "0")}:${(startDate.getMinutes() + "").padStart(2, "0")}:${(
      startDate.getSeconds() + ""
    ).padStart(2, "0")}`;
    
    let dd = startDate.setMinutes(startDate.getMinutes() - minutes);
  
    let date = new Date(dd);
    let day = date.getDate();
    let monthIndex = date.getMonth() + 1;
    let hr = date.getHours();
    let mn = date.getMinutes();
    let sc = date.getSeconds();
    let year = date.getFullYear();
    let minsSubStractDate = `${(year + "").padStart(2, "0")}-${(monthIndex + "").padStart(2, "0")}-${(
      day + ""
    ).padStart(2, "0")} ${(hr + "").padStart(
      2,
      "0"
    )}:${(mn + "").padStart(2, "0")}:${(sc + "").padStart(2, "0")}`;

    let timestamps = {
      endDate: currentDate,
      startDate: minsSubStractDate,
    };
    return timestamps;
  } else {
    return console.log("Invalid Time Selected");
  }
}
export const getEMCoreFilteredData=(data,startTS,endTS)=>{
  const selectedStartTime = new Date(startTS).getTime();
  const selectedEndTime = new Date(endTS).getTime();
  const filteredData = data.filter(item => {
      const itemTS = new Date(item.timestamp).getTime();
      return itemTS >= selectedStartTime && itemTS < selectedEndTime;
  });
  return filteredData;
}


export const emCoreUnixTimestampConvert=(timestamps)=>{
    timestamps.map(ts => {
    const date = new Date(ts * 1000); // Convert to milliseconds
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
});
}
export function getAlarmSummaryLegendsColor(label) {
  switch (label) {
    case "Critical": {
      return "#CC0815";

    }
    case "High": {
      return "#F2AC66";

    }
    case "Medium": {
      return "#FFD800";

    }
    case "Informational": {
      return "#0052FF";

    }
    case "Ok": {
      return "#51A34B";

    }
    default: {
      console.log("Invalid choice");

    }
  }

}

export function getEventLogByFunctionLegends(label) {
  switch (label) {
    case "Differential Current": {
      return "#FF9F8C";

    }
    case "Overload": {
      return "#B03A2E";

    }

    case "Single Phase Earth Fault": {
      return "#5C0006";

    }
    case "Phase C to ground fault": {
      return "#E12E2E";

    }
    case "Underfrequency": {
      return "#FF99A0";

    }
    case "Phase Unbalance": {
      return "#ED5739";

    }
    case "Voltage Protection": {
      return "#A65503";

    }

    case "Thermal Overload": {
      return "#E74C3C";

    }
    case "Overcurrent Protection": {
      return "#EC7063";

    }

    case "Overvoltage": {
      return "#F1948A";

    }
    case "Directional Earth-Fault": {
      return "#F2D7D5";

    }


    case "Three-phase fault": {
      return "#943126";

    }
    case "Breaker Failure": {
      return "#F0B27A";

    }
    case "Arc Detected": {
      return "#EDBB99";

    }
    case "Phase B to ground fault": {
      return "#D35400";

    }

    case "Phase A to ground fault": {
      return "#D35488";

    }
    default: {
      console.log("Invalid choice");

    }
  }

}
export function getFaultDurationLegendsColor(label) {
  switch (label) {
    case "2-3 seconds": {
      return "#FF9F8C";

    }
    case "6-10 seconds": {
      return "#5C0006";

    }
    case ">10 seconds": {
      return "#E12E2E";

    }
    case "<2 seconds": {
      return "#E74C3C";

    }
    case "4-5 seconds": {
      return "#ED5739";

    }
    // case "unknown": {
    //   return "#A04000";
    //   break;
    // }

    default: {
      console.log("Invalid choice");

    }
  }
}
export function getOpsStatusIcon(status) {
  switch (status) {
    case "Closed": {
      return { icon: "abb/information-circle-1", class: "closed" };

    }
    case "Charged": {
      return { icon: "abb/information-circle-1", class: "charged" };

    }
    case "Discharged": {
      return { icon: "abb/warning-circle-1", class: "discharged" };

    }
    case "Sufficient": {
      return { icon: "abb/check-mark-circle-1", class: "charged" };

    }
    case "Insufficient": {
      return { icon: "abb/warning-circle-1", class: "discharged" };

    }
    case "Normal": {
      return { icon: "abb/check-mark-circle-1", class: "charged" };

    }
    case "Tripped": {
      return { icon: "abb/warning-triangle", class: "tripped" };

    }
    case "Failed": {
      return { icon: "abb/error-circle-1", class: "failed" };

    }


    // case "unknown": {
    //   return "#A04000";
    //   break;
    // }

    default: {
      return { icon: "abb/check-mark-circle-1", class: "check-icon" };

    }
  }

}
export function replaceUrlParam(
  url,
  startTimeKey,
  newStartTime,
  endTimeKey,
  newEndTime
) {
  let currentUrl = new URL(url);
  currentUrl.searchParams.set(startTimeKey, newStartTime);
  currentUrl.searchParams.set(endTimeKey, newEndTime);
  const decodedUrl = decodeURIComponent(currentUrl.href);
  
  return decodedUrl;
}

// validation of kpi
export const validateJson = (jsonData, jsonSchema) => {
  const ajv = new Ajv();
  const validate = ajv.compile(jsonSchema);
  const isDataValid = validate(jsonData);

  if (isDataValid) {
    console.log('Success! data matches the predefined schema.');
  } else {
    const errorMessages = validate.errors.map((err) => {
      return `Error at ${err.instancePath}: ${err.message}`;
    });
    console.error('Validation Errors:', errorMessages);
  }
};
export const isJsonValid = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
};




// Initialization of Ajv instance
let ajvInstance = null;
const getAjv = () => {
  if (!ajvInstance) {
    ajvInstance = new Ajv({
      strict: false,
      removeAdditional: true,
      allErrors: true,
    });
  }
  return ajvInstance;
};


/**
Validates JSON data against a schema with optional error filtering.
@param {Object} schema - The JSON schema.
@param {Array<string>} ignoredProperties - List of property names to ignore errors for.
@returns {Object} - Validation result with success status and error messages.
*/
export const validateJsonSchema = (schema, ignoredProperties = []) => {
  const ajv = getAjv();
  const validate = ajv.compile(schema);
  const isValid = validate(schema);
 
  if (isValid) {
    console.log('Validation succeeded!');
    return { success: true };
  }
 
  // Filter errors based on ignored properties and custom errors
  const filteredErrors = validate.errors.filter((err) => {
    const isIgnored = ignoredProperties.some((prop) => err.message.includes(`'${prop}'`));
    return !isIgnored ;
  });
 
  const errorMessages = filteredErrors.map((err) => `Error at ${err.instancePath}: ${err.message}`);
 
  if (errorMessages.length > 0) {
    console.error('Validation Errors:', errorMessages);
    return { success: false, errors: errorMessages };
  }
 
  console.log('Validation succeeded with ignored errors.');
  return { success: true, ignoredErrors: validate.errors };
};
 
 
export const isJsonValidSchema = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch {
    return false;
  }
};