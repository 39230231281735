import { useContext, useEffect, useRef, useState } from "react";
import { RootContext } from "../../../contexts/root-context";
import * as echarts from "echarts";
import "./co2-emissions-forecast.css";
import { Spinner } from "@abb/abb-common-ux-react";
import { type } from "@testing-library/user-event/dist/type";
import { NZ_ERROR_LIST } from '../../../strings';

const Co2EmissionsForecast = () => {
  const { forecastData, co2EmissionForecastSelectedDuration } =
    useContext(RootContext);
  const [data, setData] = useState([]);
  const chartRef = useRef(null);
  const [isSpin, setIsSpin] = useState(true);

  const forcastedResult = forecastData?.find(
    (forecast) => forecast.name === "co2"
  )?.forecast;

  const columnsName = ["p10", "p50", "p90"];
  const colors = ["blue", "teal", "pink"];

  useEffect(() => {
    updateEmissionsForecastChartData(forcastedResult);
  }, [forecastData, co2EmissionForecastSelectedDuration]);

  const updateEmissionsForecastChartData = (forcastedResult) => {
    if (!forcastedResult || forcastedResult.length === 0) {
      setData([]);
      return;
    }

    let chartData = [];
    if (co2EmissionForecastSelectedDuration === "Monthly") {
      chartData = convertCo2ForecastToChartDataMonth(forcastedResult);
    } else {
      chartData = convertCo2ForecastToChartDataDay(forcastedResult);
    }
    if (chartData) {
      setData(chartData);
      setIsSpin(false);
    }
  };

  const convertCo2ForecastToChartDataMonth = (forecastResult) => {
    const predictionsByMonthP10 =
      forecastResult?.Predictions?.p10?.map(getObjectFromForecastWithMonth) ||
      [];
    const predictionsByMonthP50 =
      forecastResult?.Predictions?.p50?.map(getObjectFromForecastWithMonth) ||
      [];
    const predictionsByMonthP90 =
      forecastResult?.Predictions?.p90?.map(getObjectFromForecastWithMonth) ||
      [];

    const valuesByMonthP10 = groupValues(predictionsByMonthP10);
    const valuesByMonthP50 = groupValues(predictionsByMonthP50);
    const valuesByMonthP90 = groupValues(predictionsByMonthP90);

    let result = [];
    for (const key of Object.keys(valuesByMonthP10)) {
      result.push([
        key,
        valuesByMonthP10[key],
        valuesByMonthP50[key],
        valuesByMonthP90[key],
      ]);
    }
    return result;
  };

  const convertCo2ForecastToChartDataDay = (forecastResult) => {
    const predictionsByDayP10 =
      forecastResult?.Predictions?.p10?.map(getObjectFromForecastWithDay) || [];
    const predictionsByDayP50 =
      forecastResult?.Predictions?.p50?.map(getObjectFromForecastWithDay) || [];
    const predictionsByDayP90 =
      forecastResult?.Predictions?.p90?.map(getObjectFromForecastWithDay) || [];

    let result = [];
    for (let i = 0; i < predictionsByDayP10.length; i++) {
      result.push([
        formatDate(predictionsByDayP10[i].date),
        predictionsByDayP10[i].value,
        predictionsByDayP50[i]?.value || 0,
        predictionsByDayP90[i]?.value || 0,
      ]);
    }
    return result;
  };

  const groupValues = (predictions) => {
    const values = {};
    for (let prediction of predictions) {
      const key =
        co2EmissionForecastSelectedDuration === "Monthly"
          ? prediction.month
          : formatDate(prediction.date);
      if (key in values) {
        values[key] += prediction.value;
      } else {
        values[key] = prediction.value;
      }
    }
    return values;
  };

  const getObjectFromForecastWithMonth = (forecast) => {
    const ts = new Date(forecast.Timestamp);
    return {
      month: ts.toLocaleString("en-US", { month: "short" }),
      value: Math.round(forecast.Value),
    };
  };

  const getObjectFromForecastWithDay = (forecast) => {
    const ts = new Date(forecast.Timestamp);
    return {
      date: ts,
      value: Math.round(forecast.Value),
    };
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    return `${day}-${month}`;
  };

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const chart = echarts?.init(chartRef?.current);

    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        orient: "horizontal",
        top: "bottom",
      },
      xAxis: {
        type: "category",
        data: data?.map((item) => item[0]),
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          padding: 0,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          padding: 0,
          verticalAlign: "top",
          lineHeight: 10,
        },
        name: "CO2 Emissions (tons)",
        nameLocation: "center",
        nameGap: 35,
      },
      series: [
        {
          name: columnsName[0],
          type: "bar",
          data: data?.map((item) => item[1]),
          itemStyle: {
            color: colors[0],
          },
        },
        {
          name: columnsName[1],
          type: "bar",
          data: data?.map((item) => item[2]),
          itemStyle: {
            color: colors[1],
          },
        },
        {
          name: columnsName[2],
          type: "bar",
          data: data?.map((item) => item[3]),
          itemStyle: {
            color: colors[2],
          },
          barGap: 0,
        },
      ],
    };

    chart?.setOption(option);
    window.addEventListener("resize", chart.resize);

    return () => {
      window.removeEventListener("resize", chart.resize);
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {data?.length > 0 ? (
            <div className="co2-emissions-forecast-container">
              <div
                ref={chartRef}
                className="co2-emissions-forecast-resizable-chart"
              />
            </div>
          ) : (
            <p className="center-text-error">{NZ_ERROR_LIST.NO_DATA}</p>
          )}
        </>
      )}
    </>
  );
};

export default Co2EmissionsForecast;
