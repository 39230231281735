import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Dropdown, DropdownOption, Checkbox } from '@abb/abb-common-ux-react';
import "./peak-monitoring.css";
import PeriodList from '../Period-Dropdown-list/period-dropdown-list';
import peakMonitor from './peak-monitor-option.json'
// import peakJsonData from './peak-monitor-data.json'
import { validateJson, isJsonValid } from '../../util';


const PeakMonitoring = () => {
    const chartRef = useRef(null);
    const [monochrome] = React.useState(false);
    


    useEffect(() => {
        const jsonString = JSON.stringify(peakMonitor);
         
        if (isJsonValid(jsonString)) {
          validateJson(peakMonitor ,peakMonitor.jsonSchema);
        } else {
         
          console.error('Invalid JSON syntax: Missing braces or commas.');
        }
      }, []);

    useEffect(() => {
        

        const chart = echarts.init(chartRef.current);

        const option = {
            tooltip: {
                trigger: peakMonitor.tooltip.trigger
            },
            xAxis: {
                type: peakMonitor.xAxis.type,
                data: peakMonitor.xAxis.data,
                name: peakMonitor.xAxis.name,
                axisLabel: {

                    padding: peakMonitor.xAxis.axisLabel.padding // Add padding/margin between y-axis values and title
                },
                nameLocation: peakMonitor.xAxis.nameLocation,
                axisTick: {
                    alignWithLabel: peakMonitor.xAxis.axisTick.alignWithLabel
                }
            },

            yAxis: {
                type: peakMonitor.yAxis.type,

                axisLabel: {

                    padding: peakMonitor.yAxis.axisLabel.padding // Add padding/margin between y-axis values and title
                },
                name: peakMonitor.yAxis.name, // Title for y-axis
                nameLocation: peakMonitor.yAxis.nameLocation, // Set y-axis title location to start (left side)
                nameRotate: peakMonitor.yAxis.nameRotate,// Do not rotate y-axis title (name)
                nameGap: 30,  

            },
            series: peakMonitor.series.map(series => ({
                type: series.type,
                data: series.data,
                itemStyle: series.itemStyle
            }))
        };

        chart.setOption(option);

        return () => {
            chart.dispose();
        };
    }, []);

    return (
        <div className='peak-block'>
            <div className='indicator-container-line'>
                <Dropdown
                    label="Asset"
                    placeholder=""
                    monochrome={monochrome}
                >
                    {peakMonitor.dropdownOptions[0].options.map(option => (
                        <DropdownOption key={option.key} label={option.label} value={option.value} />
                    ))}
                </Dropdown>
                <PeriodList />
                <Checkbox
                    style={{ paddingTop: "35px" }}
                    monochrome={monochrome}
                    label={peakMonitor.checkbox.label}

                />
            </div>
            <div ref={chartRef} style={peakMonitor.chartStyles} />
        </div>
    );
};


export default PeakMonitoring;
