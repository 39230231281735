import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import "./power-generation.css"
import powerGeneration from './power-generation.json'
import { validateJson, isJsonValid } from '../../util';


const GaugePowerGenerator = () => {
    const chartRef = useRef(null);
    const myChart = useRef(null);


        useEffect(() => {
        const jsonString = JSON.stringify(powerGeneration);
         
        if (isJsonValid(jsonString)) {
          validateJson(powerGeneration ,powerGeneration.schema);
        } else {
         
          console.error('Invalid JSON syntax: Missing braces or commas.');
        }
      }, []);

    useEffect(() => {
        myChart.current = echarts.init(chartRef.current);

        const option = {
            series: [
                {
                    type: powerGeneration.series.type,
                    center: powerGeneration.series.center,
                    startAngle: powerGeneration.series.startAngle,
                    endAngle: powerGeneration.series.endAngle,
                    min: powerGeneration.series.min,
                    max: powerGeneration.series.max,
                    progress: {
                        show: powerGeneration.series.progress.show,
                        width: powerGeneration.series.progress.width,
                        itemStyle: {
                            color: '#51A34B', // Color for the data range
                        },
                        backgroundStyle: {
                            color: '#DBDBDB' // Color for the data track (background)
                        }
                    },
                    detail: {
                        formatter: '28%\nPower Generation',
                        offsetCenter: [0, 0],// Adjust offsetCenter to center the value text

                        textStyle: { // Add textStyle to modify font size
                            fontSize: 18, // Adjust the font size here

                        }
                    },
                    axisLine: {
                        lineStyle: {
                            width: powerGeneration.series.axisLine.lineStyle.width, // Set axis line width to 0 to hide it
                        }
                    },
                    axisLabel: {
                        show: powerGeneration.series.axisLabel.show, // Hide axis labels
                    },
                    axisTick: {
                        show: powerGeneration.series.axisTick.show, // Hide axis ticks
                    },
                    splitLine: {
                        show: powerGeneration.series.splitLine.show, // Hide split lines
                    },
                    pointer: {
                        show: powerGeneration.series.pointer.show, // Hide pointer
                    },
                    data: [
                        { value: 28 }
                    ],
                }
            ]
        };

        myChart.current.setOption(option);

    }, []);

    return (
        <div className='power-container'>
            <div className='chart-holder'>
                <div className='chart-container' ref={chartRef}></div>
            </div>
            <div className='power-info'>
                <div className='power-inner'>
                    {powerGeneration.powerMetrics.map((metric, index) => (
                        <div className="power-metrics" key={index}>
                            <span>{metric.label}</span>
                            <span style={{ fontSize: "16px", fontWeight: "bold" }}>{metric.value}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default GaugePowerGenerator;