import React, { useContext, useMemo } from "react";
import { RootContext } from "../../../contexts/root-context";
import "./metrics.css";

function MicroOptMetrics({ legends }) {
    //Currently Time filter is not using
  const { startTS } = useContext(RootContext);

  // Memoize rendering of metrics to prevent re-rendering unless legends change
  const renderMetrics = useMemo(() => {
    const renderMetric = (legend, index) => (
      <div id="dashboard-metrics" key={index}>
        <span className="metric-name">{legend.metricName}</span>
        <div id="metrics-details">
          <span className="metric-score">
            {legend.metricScore}&nbsp;&nbsp;
            <span style={{ lineHeight: "24px" }} className="metric-unit">
              {legend.metricStat}
            </span>
          </span>
        </div>
      </div>
    );

    return legends?.map((legend, index) => renderMetric(legend, index));
  }, [legends]);

  return <div style={{ backgroundColor: "white" }}>{renderMetrics}</div>;
}

export default MicroOptMetrics;
