import Sites from "./components/Net-Zero-Components/Home/sites";
import Co2Emission from "./components/Net-Zero-Components/Home/co2-emission";
import AbbBuilding from "./components/Net-Zero-Components/Sites-KPIs/abb-building";
import SiteInformation from "./components/Net-Zero-Components/Sites-KPIs/site-information";
import CO2Saving from "./components/Net-Zero-Components/Sites-KPIs/co2-savings";
import ConsumptionByOccupancy from "./components/Net-Zero-Components/Sites-KPIs/consumption-by-occupancy";
import EnergyCost from "./components/Net-Zero-Components/Sites-KPIs/energy-cost";
import Weather from "./components/Net-Zero-Components/Sites-KPIs/weather";
import NzDropdownEnergyMix from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-energy-mix";
import NzDropdownCo2EmissionForecast from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-co2-emissions-forecast";
import Co2Emissions from "./components/Net-Zero-Components/Trends/co2-emissions";
import EnergyMix from "./components/Net-Zero-Components/Trends/energy-mix";
import Co2EmissionsForecast from "./components/Net-Zero-Components/Trends/co2-emissons-forecast";
import EnergyMixForecast from "./components/Net-Zero-Components/Trends/energy-mix-forecast";
import PowerFlow from "./components/Net-Zero-Components/Trends/power-flow";
import NzDropdownPowerFlow from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-power-flow";
import NzDropdownDeviceMeasures from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-device-measures";
import DeviceMeasures from "./components/Net-Zero-Components/Trends/device-measures";
import RenewableEnergy from "./components/Net-Zero-Components/Home/renewable-energy";
import ActualPower from "./components/Net-Zero-Components/Sites-KPIs/actual- power";
import CO2ETons from "./components/Net-Zero-Components/Sites-KPIs/co2e-tons";
import GreenEnergy from "./components/Net-Zero-Components/Sites-KPIs/green-energy";
import EVChargers from "./components/Net-Zero-Components/Sites-KPIs/ev-chargers";
import SankeyDemo from "./components/Net-Zero-Components/Sites-KPIs/demo";
import NzDropdownCo2Emission from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-co2-emissions";
import NzDropdownEnergyMixForecast from "./components/Net-Zero-Components/Nz-Dropdown/nz-dropdown-energy-mix-forecast";
export const BASE_URL = `https://${process.env.REACT_APP_TD_BASE_URL}.execute-api.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_LAMBDA_ENV}/`;

export const BASE_URL_CAES = `https://${process.env.REACT_APP_CAES_ML_BASE_URL}.execute-api.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_CAES_LAMBDA_ENV}/`;
export const S3_BUCKET_DR_CAES = `s3://${process.env.REACT_APP_CAES_DR_S3_BUCKET_NAME}/`;

//Row 3
export const BASE_URL_DISTURBANCE_RECORDS = `https://${process.env.REACT_APP_DR_BASE_URL}.execute-api.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_LAMBDA_ENV}/`;

export const BASE_URL_DISTURBANCE_GENERATE_URL = `https://${process.env.REACT_APP_DR_BASE_URL}.execute-api.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazonaws.com/${process.env.REACT_APP_LAMBDA_ENV}/cognien-abb-dr/url/generate?`;

export const BASE_URL_MFA = `https://${process.env.REACT_APP_AWS_COGNITO_MFA_URL}.auth.${process.env.REACT_APP_AWS_COGNITO_REGION}.amazoncognito.com/mfa?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid+profile&redirect_uri=https%3A%2F%2F${process.env.REACT_APP_AWS_COGNITO_MFA_ENV}.demo.cognien.abb%2F`;

export const ERROR_LIST = {
  ERROR_FETCH_DATA: `There was a problem fetching the data`,
  ALARM_SUMMARY_EMPTY_DATA: `There are no alarms to display at this time.`,
  NO_EVENTS: `There are no events available at this time.`,
  NO_RECORDS: `There are no records available at this time.`,
  NO_DATA: `There is no data available at this time.`,
  NO_NOTIFICATIONS: `There are no new notifications at this time.`,
};

export const MESSAGES = {
  SIGN_IN_TRIGGER: "Verifying Credentials, Please Wait...",
  SIGN_IN_TIME_OUT_TRIGGER: "",
  POST_SIGN_WAITING: "Waiting for User Mail Verification",
  POST_SIGN_UP_TRIGGER:
    "Thank you for creating an account with CogniEN.\n An email has been sent to your registered email. Please verify it to obtain approval from the administrators",
  IN_SIGNUP_TRIGGER: "Submitting Request...",
  CONFIRM_USER_TITLE: "Confirm User Details",
  CONFIRM_USER_BODY: "Are you sure you want to save user details?",
  CONFIRMED_USER_TITLE: "Updated User Details",
  CONFIRMED_USER_BODY:
    "User Details updated successfully. The updates will be reflected in the next login",
  SUBSTATION_AREAS_LOAD_MESSAGE:
    "Please wait while we fetch the substations from the assigned operation areas",
  SUBSTATION_LOADING: "Loading substations...",
};

//EM Widgets Section


export const NZ_BASE_URL =
  "https://bzxhlhpxj7.execute-api.us-east-1.amazonaws.com/api";

export const NZ_ERROR_LIST = {
  ERROR_FETCH_DATA: `There was a problem fetching the data`,
  ALARM_SUMMARY_EMPTY_DATA: `There are no alarms to display at this time.`,
  NO_EVENTS: `There are no events available at this time.`,
  NO_RECORDS: `There are no records available at this time.`,
  NO_DATA: `There is no data available at this time.`,
  NO_NOTIFICATIONS: `There are no new notifications at this time.`,
};

export const SITE_KPIS_CARD_JSON = [
  {
    widgetName: "ABB Building 1",
    widgetType: "small",
    nzcomponent: <AbbBuilding />,
    enabled: true,
  },
  {
    widgetName: "Actual Power (kW)",
    widgetType: "small",
    nzcomponent: <ActualPower />,
    enabled: true,
  },
  {
    widgetName: "ev Chargers (kW)",
    widgetType: "small",
    nzcomponent: <EVChargers />,
    enabled: true,
  },

  {
    widgetName: "Site Information",
    widgetType: "small",
    nzcomponent: <SiteInformation />,
    enabled: true,
  },
  {
    widgetName: "CO2e (tons)",
    widgetType: "small",
    nzcomponent: <CO2ETons />, //
    enabled: true,
  },
  {
    widgetName: "CO2e Savings (tons)",
    widgetType: "small",
    nzcomponent: <CO2Saving />, //
    enabled: true,
  },
  {
    widgetName: "Weather",
    widgetType: "small",
    nzcomponent: <Weather />,
    enabled: true,
  },

  {
    widgetName: "Energy Cost(€)",
    widgetType: "small",
    nzcomponent: <EnergyCost />,
    enabled: true,
  },

  {
    widgetName: "Consumption by Occupancy",
    widgetType: "small",
    nzcomponent: <ConsumptionByOccupancy />,
    enabled: true,
  },
  {
    widgetName: "Green Energy",
    widgetType: "small",
    nzcomponent: <GreenEnergy />,
    enabled: true,
  },
  {
    widgetName: "Energy Flow",
    widgetType: "medium",
    nzcomponent: <SankeyDemo />,
    enabled: true,
  },
];

export const HOME_CARD_JSON = [
  {
    widgetName: "Sites",
    widgetType: "extra-large",
    nzcomponent: <Sites />,
    enabled: true,
  },
  {
    widgetName: "CO2 Emissions",
    widgetType: "large",
    nzcomponent: <Co2Emission />,
    enabled: true,
  },
  {
    widgetName: "Renewable Energy",
    widgetType: "large",
    nzcomponent: <RenewableEnergy />,
    enabled: true,
  },
];

export const TRENDS_CARD_JSON = [
  {
    widgetName: "CO2 Emissions",
    widgetType: "trends",
    nzcomponent: <Co2Emissions />,
    dropdownComponent: <NzDropdownCo2Emission />,
    enabled: true,
  },
  {
    widgetName: "CO2 Emissions Forecast",
    widgetType: "trends",
    nzcomponent: <Co2EmissionsForecast />,
    dropdownComponent: <NzDropdownCo2EmissionForecast />,
    enabled: true,
  },
  {
    widgetName: "Energy Mix",
    widgetType: "trends",
    nzcomponent: <EnergyMix />,
    dropdownComponent: <NzDropdownEnergyMix />,
    enabled: true,
  },
  {
    widgetName: "Energy Mix Forecast",
    widgetType: "trends",
    nzcomponent: <EnergyMixForecast />,
    dropdownComponent: <NzDropdownEnergyMixForecast />,
    enabled: true,
  },
  {
    widgetName: "Power Flow",
    widgetType: "trends",
    nzcomponent: <PowerFlow />,
    dropdownComponent: <NzDropdownPowerFlow />,
    enabled: true,
  },
  {
    widgetName: "Device Measures",
    widgetType: "trends",
    nzcomponent: <DeviceMeasures />,
    dropdownComponent: <NzDropdownDeviceMeasures />,
    enabled: true,
  },
];
//EM Core Settings
export const EMCORE_TABS = ["Network", "Load Profiles", "Battery SoC", "Generation", "Grid"]
export const EMCORE_BASE_API_URL="https://qcu8nocy83.execute-api.ap-south-1.amazonaws.com/prod/";
export const TEMP_EMCORE_BASE_PREDICTION_API_URL = "https://mvpfvetulf.execute-api.ap-south-1.amazonaws.com/prod/evaluate";

export const MICROGRID_MESSAGES = {
  NO_FILE_UPLOADED_NOTI: `Update input parameter setting for optimization results.`,
  NETWORK_FILES_UPLOAD_SUCCESS: `Network Parameters updated successfully`,
  NETWORK_FILES_UPLOAD_FAILURE: `There was a problem uploading the data`,
  OBJ_FUN_DATA_SUCCESS: `Objective Functions updated successfully`,
  OBJ_FUN_DATA_FAILURE: `There was a problem uploading the data`,
}

export const EMCORE_DASHBOARD_BASE_API_URL=localStorage.getItem("dashboard-charts-s3-url") ? localStorage.getItem("dashboard-charts-s3-url") :"https://emcore-data-bucket-test.s3.amazonaws.com/output";


export const EMCORE_ERROR_LIST = {
  ERROR_FETCH_DATA: `There was a problem fetching the data`,
  ERROR_FILE_NOT_UPLOADED: `There is no data available`,
  ALARM_SUMMARY_EMPTY_DATA: `There are no alarms to display at this time.`,
  NO_EVENTS: `There are no events available at this time.`,
  NO_RECORDS: `There are no records available at this time.`,
  NO_DATA: `There is no data available at this time.`,
 
};
export const postRequestUrlPrefix = "https://api.dev.demo.cognien.abb/proofer/";