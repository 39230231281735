import React, { useEffect, useRef, useState, useMemo, useCallback } from "react";
import * as echarts from "echarts";
import axios from "axios";
import { Link, Spinner } from "@abb/abb-common-ux-react";
import { NZ_ERROR_LIST } from '../../../strings';
import jsonSchema from "./home.json";

function RenewableEnergy() {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const [isSpin, setIsSpin] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const fetchRenewableEnergyData = useCallback(async () => {
    setIsSpin(true);
    try {
      const response = await axios.get(
        "https://bzxhlhpxj7.execute-api.us-east-1.amazonaws.com/api/multisite_analysis"
      );
      setData(response.data);
      setError(false);
    } catch (err) {
      setError(true);
    } finally {
      setIsSpin(false);
    }
  }, []);

   useEffect(() => {
    fetchRenewableEnergyData();
    const interval = setInterval(fetchRenewableEnergyData, 15 * 60 * 1000);
    return () => clearInterval(interval);
  }, [fetchRenewableEnergyData]);

  const formattedData = useMemo(() => {
    return data?.map((item) => ({
      name: item.name,
      value1: item.solar,
      value2: item.utility,
    }));
  }, [data]);

  const chartOptions = useMemo(() => {
    return {
      title: {
        text: jsonSchema.RENEWABLE_ENERGY.CHART_TITLE,
        textStyle: {
          fontSize: 14,
          padding: [10, 0, 0, 0],
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        orient: jsonSchema.RENEWABLE_ENERGY.CHART_LEGEND_ORIENT,
        right: jsonSchema.RENEWABLE_ENERGY.CHART_LEGEND_RIGHT,
      },
      xAxis: {
        type: "category",
        data: formattedData?.map((item) => item.name),
        axisTick: {
          alignWithLabel: true,
        },
        name: jsonSchema.RENEWABLE_ENERGY.CHART_X_AXIS_NAME,
        axisLabel: {
          padding: 0,
        },
        nameLocation: "middle",
        nameGap: 35,
        nameTextStyle: jsonSchema.RENEWABLE_ENERGY.CHART_X_AXIS_NAME_STYLE,
      },
      yAxis: {
        type: "value",
        axisLabel: {
          show: false,
        },
        name: jsonSchema.RENEWABLE_ENERGY.CHART_Y_AXIS_NAME,
        nameLocation: "center",
        nameTextStyle: jsonSchema.RENEWABLE_ENERGY.CHART_Y_AXIS_NAME_STYLE,
      },
      series: jsonSchema.RENEWABLE_ENERGY.SERIES.map((series) => ({
        name: series.name,
        type: series.type,
        stack: series.stack,
        data: formattedData?.map((item) =>
          series.name === "Utility" ? item.value2 : item.value1
        ),
        itemStyle: { color: series.color },
      })),
    };
  }, [formattedData]);

  const resizeChart = useCallback(() => {
    chartInstance.current?.resize();
  }, []);

  useEffect(() => {
    if (!formattedData || formattedData.length === 0) return;
    if (!chartInstance.current) {
      chartInstance.current = echarts.init(chartRef.current);
    }
    chartInstance.current.setOption(chartOptions);
    window.addEventListener("resize", resizeChart);
    return () => {
      window.removeEventListener("resize", resizeChart);
      chartInstance.current?.dispose();
      chartInstance.current = null;
    };
  }, [chartOptions, resizeChart, formattedData]);

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {error ? (
            <span style={{ margin: "auto" }} color="dark-grey" size="small">
              {NZ_ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={fetchRenewableEnergyData}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data?.length > 0 ? (
                <div
                  className="renewable-energy-container"
                  style={{ width: "90%" }}
                >
                  <div
                    ref={chartRef}
                    style={{
                      width: "100%",
                      height: "300px",
                      marginTop: "0px",
                    }}
                  />
                </div>
              ) : (
                <p className="center-text-error">{NZ_ERROR_LIST.NO_DATA}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default React.memo(RenewableEnergy);
