import axios from "axios";
import React, { lazy, useCallback, useContext, useEffect, useState } from "react";
import { TEMP_EMCORE_BASE_PREDICTION_API_URL } from "../../strings";
import microGridAPICall from "../../components/Microgrid-Opt-Components/api";
import { RootContext } from "../../contexts/root-context";
const MGCard = lazy(() => import("../../components/Microgrid-Opt-Components/MGCards/mgcard"));
const CreateCharts = lazy(() => import("../../components/Microgrid-Opt-Components/Model-prediction-tab/Loads-accuracy/loads-accuracy"));
const GreenEnergyAccuracyPrice = lazy(() => import("../../components/Microgrid-Opt-Components/Model-prediction-tab/Green-energy-price-accuracy/green-eng-price-accuracy"));
const GreenEnergyAccuracy = lazy(() => import("../../components/Microgrid-Opt-Components/Model-prediction-tab/Green-energy-accuracy/green-accuracy"));

const FetchAccuracyWidgets = (props) => {
  const { chartsData } = props;
  console.log("chartsData", chartsData)
  const { microGridFilteredDropdownList } = useContext(RootContext);
  const comparisonSet = new Set(microGridFilteredDropdownList); // Convert array to Set for faster lookup
  console.log("dpSelectedValue", microGridFilteredDropdownList)
  const filteredLoadNames = chartsData?.filter(chart => comparisonSet.has(chart.title));
  console.log("filteredLoadNames", filteredLoadNames)

  return (
    <>
   {filteredLoadNames?.length > 0 ? (
  filteredLoadNames?.map((item, index) => (
    <MGCard
      key={index}
      size="medium"
      widgetName={item.title}
      component={<CreateCharts chartConfig={[item]} />}
      applyScroll="true"
    />
  ))
) : (
  <p>No data available</p>
)}

    </>
  );
};

export default FetchAccuracyWidgets;
