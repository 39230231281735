import React, { useContext, useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { RootContext } from "../../../contexts/root-context";
import "./co2-emissions.css";
import { Spinner } from "@abb/abb-common-ux-react";
import { NZ_ERROR_LIST } from "../../../strings";

function Co2Emissions() {
  const { powerTrendsData, co2EmissionSelectedDuration } = useContext(RootContext);
  const energyTrends = powerTrendsData?.energy_trend;
  const chartRef = useRef(null);
  const [data, setData] = useState([]);
  const [isSpin, setIsSpin] = useState(true);

  useEffect(() => {
    updateEmissionsChartData();
  }, [powerTrendsData, co2EmissionSelectedDuration]);

  const updateEmissionsChartData = () => {
    if (!Array.isArray(energyTrends) || energyTrends.length === 0) {
      setData([]);
      return;
    }

    let measures = [];

    if (energyTrends.length > 0) {
      if (co2EmissionSelectedDuration === "Last 24 hours") {
        measures =
          energyTrends.find((trend) => trend.name === "day")?.value || [];
      } else if (co2EmissionSelectedDuration === "Last 30 days") {
        measures =
          energyTrends.find((trend) => trend.name === "month")?.value || [];
      } else {
        measures =
          energyTrends.find((trend) => trend.name === "month")?.value || [];
      }
    }

    const result = measures?.map(mapEnergyTrendToEmissionsArray);
    if (result) {
      setData(result);
      setIsSpin(false);
    }
  };

  const mapEnergyTrendToEmissionsArray = (trendMeasure) => {
    const date = new Date(trendMeasure.datetime);
    let formattedDate;

    if (co2EmissionSelectedDuration === "Last 24 hours") {
      console.log("24 hours selected inside mapEngery");
      formattedDate = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
    } else if (co2EmissionSelectedDuration === "Last 30 days") {
      console.log("30 days selected inside mapEngery");
      formattedDate = date
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
        })
        .replace(/\//g, "-");
    } else {
      formattedDate = date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    return [formattedDate, (trendMeasure.utility * 0.5) / 1000];
  };

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    const chart = echarts?.init(chartRef?.current);
    const option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        orient: "horizontal",
        top: "bottom",
      },
      xAxis: {
        type: "category",
        data: data?.map((item) => item[0]),
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          padding: 0,
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          padding: 0,
          verticalAlign: "top",
          lineHeight: 10,
        },
        name: "CO2 Emissions (tons)",
        nameLocation: "center",
        nameGap: 35,
      },
      series: [
        {
          name: "Emissions",
          type: "bar",
          data: data?.map((item) => item[1]),
          itemStyle: {
            color: "#808080",
          },
        },
      ],
    };

    chart?.setOption(option);
    window.addEventListener("resize", chart.resize);
    return () => {
      window.removeEventListener("resize", chart.resize);
      chart.dispose();
    };
  }, [data]);

  return (
    <>
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          size="small"
        ></Spinner>
      ) : (
        <>
          {data?.length > 0 ? (
            <div className="co2-emissions-container">
              <div
                ref={chartRef}
                className="co2-emissions-resizable-chart"
              />
            </div>
          ) : (
            <p className="center-text-error">{NZ_ERROR_LIST.NO_DATA}</p>
          )}
        </>
      )}
    </>
  );
}

export default Co2Emissions;
